import { IconButton, Tooltip, ClickAwayListener } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useState } from "react";

type EditorToolTipProps = {
  description: string | undefined | null;
  style?: React.CSSProperties;
};

export const EditorToolTip: React.FC<EditorToolTipProps> = ({
  description,
  style,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClick = () => setOpen((v) => !v);
  const handleTooltipClose = () => setOpen(false);

  return (
    <>
      {description && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            open={open}
            onClose={handleTooltipClose}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={description}
            placement="top"
            arrow
          >
            <IconButton onClick={handleTooltipClick} edge="end">
              <HelpIcon style={style} fontSize="medium" />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      )}
    </>
  );
};
