export const schema = {
    "models": {
        "GameSystem": {
            "name": "GameSystem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "templates": {
                    "name": "templates",
                    "isArray": true,
                    "type": {
                        "model": "GameCardTemplate"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "gameSystemTemplatesId"
                    }
                },
                "icons": {
                    "name": "icons",
                    "isArray": true,
                    "type": {
                        "nonModel": "GameSystemIcon"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "GameSystems",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                }
            ]
        },
        "GameCardTemplate": {
            "name": "GameCardTemplate",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "slug": {
                    "name": "slug",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "system": {
                    "name": "system",
                    "isArray": false,
                    "type": {
                        "model": "GameSystem"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "gameSystemTemplatesId"
                    }
                },
                "elements": {
                    "name": "elements",
                    "isArray": true,
                    "type": {
                        "nonModel": "TemplateElement"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "properties": {
                    "name": "properties",
                    "isArray": true,
                    "type": {
                        "nonModel": "TemplateProperty"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "size": {
                    "name": "size",
                    "isArray": false,
                    "type": {
                        "nonModel": "Size"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "printSize": {
                    "name": "printSize",
                    "isArray": false,
                    "type": {
                        "nonModel": "Size"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "titleElement": {
                    "name": "titleElement",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "exampleCardImage": {
                    "name": "exampleCardImage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "GameCardTemplates",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                }
            ]
        },
        "GameCard": {
            "name": "GameCard",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "templateID": {
                    "name": "templateID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "template": {
                    "name": "template",
                    "isArray": false,
                    "type": {
                        "model": "GameCardTemplate"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "templateID"
                    }
                },
                "slots": {
                    "name": "slots",
                    "isArray": true,
                    "type": {
                        "nonModel": "GameCardSlot"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "propertyValues": {
                    "name": "propertyValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "PropertyValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "GameCards",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                }
            ]
        }
    },
    "enums": {
        "CardSide": {
            "name": "CardSide",
            "values": [
                "FRONT",
                "BACK",
                "BOTH"
            ]
        },
        "ElementSource": {
            "name": "ElementSource",
            "values": [
                "CONSTANT",
                "DERIVED_VALUE",
                "DERIVED_LOOKUP",
                "USER_INPUT"
            ]
        },
        "ElementConditionType": {
            "name": "ElementConditionType",
            "values": [
                "PROPERTY",
                "SLOT"
            ]
        },
        "ElementType": {
            "name": "ElementType",
            "values": [
                "TEXT",
                "MULTITEXT",
                "IMAGE",
                "SELECT_TEXT",
                "SELECT_IMAGE",
                "SWITCH"
            ]
        },
        "TextWrapStyleType": {
            "name": "TextWrapStyleType",
            "values": [
                "NORMAL",
                "PRE",
                "NOWRAP",
                "PRE_WRAP",
                "PRE_LINE",
                "BREAK_SPACES"
            ]
        },
        "DrawStyleType": {
            "name": "DrawStyleType",
            "values": [
                "NORMAL",
                "WITH_STROKE"
            ]
        },
        "PropertyEditorType": {
            "name": "PropertyEditorType",
            "values": [
                "SWATCH_PICKER",
                "RADIO"
            ]
        }
    },
    "nonModels": {
        "GameSystemIcon": {
            "name": "GameSystemIcon",
            "fields": {
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "shortCode": {
                    "name": "shortCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "iconPath": {
                    "name": "iconPath",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "TemplateElement": {
            "name": "TemplateElement",
            "fields": {
                "ref": {
                    "name": "ref",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "position": {
                    "name": "position",
                    "isArray": false,
                    "type": {
                        "nonModel": "ElementPosition"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "source": {
                    "name": "source",
                    "isArray": false,
                    "type": {
                        "enum": "ElementSource"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "sourceData": {
                    "name": "sourceData",
                    "isArray": false,
                    "type": {
                        "nonModel": "ElementSourceData"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ElementType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "typeData": {
                    "name": "typeData",
                    "isArray": false,
                    "type": {
                        "nonModel": "ElementTypeData"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "renderConditions": {
                    "name": "renderConditions",
                    "isArray": true,
                    "type": {
                        "nonModel": "ElementCondition"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "displacesByCoordinates": {
                    "name": "displacesByCoordinates",
                    "isArray": false,
                    "type": {
                        "nonModel": "DisplaceCoordinates"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "displacedByRefs": {
                    "name": "displacedByRefs",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "ElementPosition": {
            "name": "ElementPosition",
            "fields": {
                "side": {
                    "name": "side",
                    "isArray": false,
                    "type": {
                        "enum": "CardSide"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "x": {
                    "name": "x",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "y": {
                    "name": "y",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "z": {
                    "name": "z",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ElementSourceData": {
            "name": "ElementSourceData",
            "fields": {
                "CONSTANT": {
                    "name": "CONSTANT",
                    "isArray": false,
                    "type": {
                        "nonModel": "ConstantElementData"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "DERIVED_LOOKUP": {
                    "name": "DERIVED_LOOKUP",
                    "isArray": false,
                    "type": {
                        "nonModel": "DerivedLookupElementData"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "DERIVED_VALUE": {
                    "name": "DERIVED_VALUE",
                    "isArray": false,
                    "type": {
                        "nonModel": "DerivedCloneElementData"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "USER_INPUT": {
                    "name": "USER_INPUT",
                    "isArray": false,
                    "type": {
                        "nonModel": "UserInputElementData"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ConstantElementData": {
            "name": "ConstantElementData",
            "fields": {
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "DerivedLookupElementData": {
            "name": "DerivedLookupElementData",
            "fields": {
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": {
                        "nonModel": "DerivedLookupElementEntry"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "DerivedLookupElementEntry": {
            "name": "DerivedLookupElementEntry",
            "fields": {
                "conditions": {
                    "name": "conditions",
                    "isArray": true,
                    "type": {
                        "nonModel": "ElementCondition"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ElementCondition": {
            "name": "ElementCondition",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ElementConditionType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "ref": {
                    "name": "ref",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "DerivedCloneElementData": {
            "name": "DerivedCloneElementData",
            "fields": {
                "origin": {
                    "name": "origin",
                    "isArray": false,
                    "type": {
                        "nonModel": "DerivedCloneElementOrigin"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DerivedCloneElementOrigin": {
            "name": "DerivedCloneElementOrigin",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "ElementConditionType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "ref": {
                    "name": "ref",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "UserInputElementData": {
            "name": "UserInputElementData",
            "fields": {
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "icon": {
                    "name": "icon",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": {
                        "nonModel": "UserSelectElementEntry"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "UserSelectElementEntry": {
            "name": "UserSelectElementEntry",
            "fields": {
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "icon": {
                    "name": "icon",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ElementTypeData": {
            "name": "ElementTypeData",
            "fields": {
                "IMAGE": {
                    "name": "IMAGE",
                    "isArray": false,
                    "type": {
                        "nonModel": "ImageElementData"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TEXT": {
                    "name": "TEXT",
                    "isArray": false,
                    "type": {
                        "nonModel": "TextElementData"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ImageElementData": {
            "name": "ImageElementData",
            "fields": {
                "size": {
                    "name": "size",
                    "isArray": false,
                    "type": {
                        "nonModel": "PercentageSize"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "PercentageSize": {
            "name": "PercentageSize",
            "fields": {
                "width": {
                    "name": "width",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "height": {
                    "name": "height",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "TextElementData": {
            "name": "TextElementData",
            "fields": {
                "fontSize": {
                    "name": "fontSize",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "color": {
                    "name": "color",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "font": {
                    "name": "font",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "align": {
                    "name": "align",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "padding": {
                    "name": "padding",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "lineHeight": {
                    "name": "lineHeight",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "textWrapStyle": {
                    "name": "textWrapStyle",
                    "isArray": false,
                    "type": {
                        "enum": "TextWrapStyleType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "textDrawStyle": {
                    "name": "textDrawStyle",
                    "isArray": false,
                    "type": {
                        "enum": "DrawStyleType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "textShrinkSettings": {
                    "name": "textShrinkSettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "TextShrinkSettings"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "validation": {
                    "name": "validation",
                    "isArray": false,
                    "type": {
                        "nonModel": "ElementValidation"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "useIcons": {
                    "name": "useIcons",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TextShrinkSettings": {
            "name": "TextShrinkSettings",
            "fields": {
                "width": {
                    "name": "width",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "height": {
                    "name": "height",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "minFontSize": {
                    "name": "minFontSize",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ElementValidation": {
            "name": "ElementValidation",
            "fields": {
                "maxLength": {
                    "name": "maxLength",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "minValue": {
                    "name": "minValue",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "maxValue": {
                    "name": "maxValue",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DisplaceCoordinates": {
            "name": "DisplaceCoordinates",
            "fields": {
                "x": {
                    "name": "x",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "y": {
                    "name": "y",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TemplateProperty": {
            "name": "TemplateProperty",
            "fields": {
                "ref": {
                    "name": "ref",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "values": {
                    "name": "values",
                    "isArray": true,
                    "type": {
                        "nonModel": "TemplatePropertyValue"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "editorType": {
                    "name": "editorType",
                    "isArray": false,
                    "type": {
                        "enum": "PropertyEditorType"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "TemplatePropertyValue": {
            "name": "TemplatePropertyValue",
            "fields": {
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "editorOptions": {
                    "name": "editorOptions",
                    "isArray": false,
                    "type": {
                        "nonModel": "TemplatePVEditorTypeData"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "TemplatePVEditorTypeData": {
            "name": "TemplatePVEditorTypeData",
            "fields": {
                "SWATCH_PICKER": {
                    "name": "SWATCH_PICKER",
                    "isArray": false,
                    "type": {
                        "nonModel": "SwatchPickerData"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SwatchPickerData": {
            "name": "SwatchPickerData",
            "fields": {
                "iconSelected": {
                    "name": "iconSelected",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "iconUnselected": {
                    "name": "iconUnselected",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Size": {
            "name": "Size",
            "fields": {
                "width": {
                    "name": "width",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "height": {
                    "name": "height",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "SwatchPickerOption": {
            "name": "SwatchPickerOption",
            "fields": {
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "icon": {
                    "name": "icon",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "GameCardSlot": {
            "name": "GameCardSlot",
            "fields": {
                "elementRef": {
                    "name": "elementRef",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PropertyValue": {
            "name": "PropertyValue",
            "fields": {
                "propertyRef": {
                    "name": "propertyRef",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        }
    },
    "version": "075ebe15c128e5756fe15faa99cde235"
};