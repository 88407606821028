import { jsPDF } from "jspdf";
import { CardSide, Size } from "../models";
import html2canvas from "html2canvas";

const downloadAnchor = document.createElement("a");

const extForData = (data: string) =>
  data.substring("data:image/".length, data.indexOf(";base64"));

export const downloadCardSide = async (
  domElement: HTMLDivElement,
  side: CardSide.FRONT | CardSide.BACK
) => {
  const canvas = await html2canvas(domElement, {
    onclone: onclone(domElement),
  });
  const data = canvas.toDataURL("image/png");

  const extension = extForData(data);
  downloadAnchor.href = data;
  downloadAnchor.download = `CARD-${side}.${extension}`;
  downloadAnchor.click();
};

const pageMargins = {
  top: 19,
  left: 19,
};

export const downloadCardPdf = async (
  cardFrontDomEl: HTMLDivElement,
  cardBackDomEl: HTMLDivElement,
  printSize: Size
) => {
  const { width, height } = printSize;
  const { top: mTop, left: mLeft } = pageMargins;
  const pdf = new jsPDF();

  const frontCanvas = await html2canvas(cardFrontDomEl, {
    onclone: onclone(cardFrontDomEl),
  });
  const frontData = frontCanvas.toDataURL("image/png");
  if (frontData) {
    const x = 0 + mLeft;
    const y = 0 + mTop;
    pdf.addImage(frontData, extForData(frontData), x, y, width, height);
  }

  const backCanvas = await html2canvas(cardBackDomEl, {
    onclone: onclone(cardBackDomEl),
  });
  const backData = backCanvas.toDataURL("image/png");
  if (backData) {
    const x = width + mLeft;
    const y = 0 + mTop;
    pdf.addImage(backData, extForData(backData), x, y, width, height);
  }

  pdf.setLineDashPattern([2], 1);
  // horizontal cut lines
  pdf.line(mLeft - 5, mTop, mLeft + width * 2 + 5, mTop);
  pdf.line(mLeft - 5, mTop + height, mLeft + width * 2 + 5, mTop + height);

  // vertical cut lines
  pdf.line(mLeft, mTop - 5, mLeft, mTop + height + 5);
  pdf.line(mLeft + width * 2, mTop - 5, mLeft + width * 2, mTop + height + 5);

  // fold line
  pdf.setLineDashPattern([], 1);
  pdf.setDrawColor("#333");
  pdf.line(width + mLeft, mTop - 5, width + mLeft, mTop + height + 5);

  pdf.save("card.pdf");
};

/*Onclone Callback function which is called when the Document has been cloned for rendering,
 can be used to modify the contents that will be rendered without affecting the original source document */
const onclone = (domElement: HTMLDivElement) => (clonedElement: Document) => {
  if (!domElement.id) return;
  const element = clonedElement.getElementById(domElement.id);
  if (!element) return;
  element.style.transform = "";
  element.style.borderRadius = "";

  if (!element.parentElement) return;
  element.parentElement.style.transform = "";
};
