import { findOrigin } from "../../../../services/conditions";
import { DataProducer } from "./types";

export const derivedValueDataProducer: DataProducer = (element, card) => {
  const origin = element.sourceData.DERIVED_VALUE?.origin;
  if (!origin) {
    console.error(
      `Element: ${element} does not have an origin specified to clone from`
    );
    return;
  }

  return findOrigin(card, origin)?.value;
};
