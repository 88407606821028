import { useState, useEffect } from "react";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import {
  findCardPropValByRef,
  upsertCardPropValByRef,
} from "../../../services/card";
import { EditorTitle } from "../EditorTitle";
import { PropertyEditorProps } from "./types";

export const RadioEditor: React.FC<PropertyEditorProps> = ({
  property,
  card,
}) => {
  const [value, setValue] = useState<string>(
    findCardPropValByRef(card, property.ref)?.value || ""
  );

  useEffect(() => {
    if (value) {
      upsertCardPropValByRef(card, property.ref, value);
    }
  }, [value]);

  const { label, description, values } = property;

  return (
    <Grid item container>
      <Grid item container>
        <EditorTitle title={label} toolTipText={description} />
      </Grid>
      <Grid item>
        <RadioGroup onChange={(_, v) => setValue(v)}>
          {values.map(({ value: thisValue, label }) => (
            <FormControlLabel
              key={thisValue}
              value={thisValue}
              checked={value === thisValue}
              control={<Radio />}
              label={label}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};
