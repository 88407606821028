import { Navigate, Route, Routes } from "react-router-dom";
import { CreateEditCard, Home, Library } from "../views";

export const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:systemSlug/library" element={<Library />} />
      <Route
        path="/:systemSlug/library/:templateSlug/cards/new"
        element={<CreateEditCard />}
      />
      <Route
        path="/:systemSlug/library/:templateSlug/cards/:cardId"
        element={<CreateEditCard />}
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
