import { DataStore } from "aws-amplify";
import {
  GameCardTemplate,
  TemplateProperty,
  TemplateElement,
  ElementSource,
} from "../../models";
import { getSystemBySlug, getTemplateBySlug } from "./initialiseData";

const templateSlug = "bardsungHero";

export const initialiseHeroTemplate = async () => {
  const bardsungSystem = await getSystemBySlug("bardsung");
  let bardsungHeroTemplate: GameCardTemplate = await getTemplateBySlug(
    templateSlug
  );

  if (!bardsungHeroTemplate) {
    console.debug("no existing Bardsung Hero Card template found, creating");

    const properties: TemplateProperty[] = [
      {
        ref: "path",
        label: "Choose your hero's path",
        values: [
          {
            value: "beasts",
            label: "Path of Beasts",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/beasts_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/beasts_selected.svg`,
              },
            },
          },
          {
            value: "blood",
            label: "Path of Blood",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/blood_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/blood_selected.svg`,
              },
            },
          },
          {
            value: "damnation",
            label: "Path of Damnation",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/damnation_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/damnation_selected.svg`,
              },
            },
          },
          {
            value: "elements",
            label: "Path of Elements",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/elements_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/elements_selected.svg`,
              },
            },
          },
          {
            value: "faith",
            label: "Path of Faith",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/faith_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/faith_selected.svg`,
              },
            },
          },
          {
            value: "flame",
            label: "Path of Flame",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/flame_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/flame_selected.svg`,
              },
            },
          },
          {
            value: "honour",
            label: "Path of Honour",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/honour_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/honour_selected.svg`,
              },
            },
          },
          {
            value: "omens",
            label: "Path of Omens",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/omens_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/omens_selected.svg`,
              },
            },
          },
          {
            value: "serenity",
            label: "Path of Serenity",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/serenity_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/serenity_selected.svg`,
              },
            },
          },
          {
            value: "shadow",
            label: "Path of Shadow",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/shadow_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/shadow_selected.svg`,
              },
            },
          },
          {
            value: "spirits",
            label: "Path of Spirits",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/spirits_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/spirits_selected.svg`,
              },
            },
          },
          {
            value: "steel",
            label: "Path of Steel",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/steel_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/steel_selected.svg`,
              },
            },
          },
          {
            value: "wilds",
            label: "Path of Wilds",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/wilds_unselected.svg`,
                iconSelected: `/icons/${templateSlug}/wilds_selected.svg`,
              },
            },
          },
        ],
        editorType: "SWATCH_PICKER",
      },
      {
        ref: "heroImageStyle",
        label: "Does your hero art have a transparent background?",
        description: "If you're not sure, try 'no' first!",
        values: [
          {
            label: "Yes, there's a transparent background",
            value: "transparent",
            editorOptions: {},
          },
          {
            label: "No, it's a solid image",
            value: "solid",
            editorOptions: {},
          },
        ],
        editorType: "RADIO",
      },
    ];

    const elements: TemplateElement[] = [
      {
        ref: "heroName",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Name your hero",
          },
        },
        type: "TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 25,
            align: "center",
            padding: 5,
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxLength: 25,
            },
            textShrinkSettings: {
              minFontSize: 12,
              width: "48.46%",
              height: "6.32%",
            },
          },
        },
        position: {
          side: "BOTH",
          x: "26.44%",
          y: "6.28%",
          z: 30,
        },
      },
      {
        ref: "heroImageSolid",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Hero Art",
            description:
              "Upload your own hero art (411x562 pixels). Transparent PNGs work best.",
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "0%",
          y: "0%",
          z: 5,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "heroImageStyle",
            value: "solid",
          },
        ],
      },
      {
        ref: "heroImageTransparent",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Hero Art",
            description:
              "Upload your own hero art (411x562 pixels). Transparent PNGs work best.",
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "0%",
          y: "12.44%",
          z: 5,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "heroImageStyle",
            value: "transparent",
          },
        ],
      },
      {
        ref: "heroBackground",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Beasts.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Blood.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Damnation.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Elements.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Faith.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Flames.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Honour.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Omens.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Serenity.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Shadow.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Spirits.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Steel.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Wilds.jpeg`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "BOTH",
          x: "0%",
          y: "0%",
          z: 0,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "heroImageStyle",
            value: "transparent",
          },
        ],
      },
      {
        ref: "heroFrame",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Beasts-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Blood-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Damnation-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Elements-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Faith-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Flame-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Honour-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Omens-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Serenity-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Shadow-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Spirits-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Steel-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Hero-Profile-Card-BG-Wilds-Frame.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "BOTH",
          x: "0%",
          y: "0%",
          z: 10,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "heroImageStyle",
            value: "solid",
          },
        ],
      },
      {
        ref: "heroSpecialRulesFrame",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/specialRules.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "82.07%",
              height: "16.94%",
            },
          },
        },
        position: {
          side: "BOTH",
          x: "8.99%",
          y: "77.69%",
          z: 25,
        },
      },
      {
        ref: "heroTitleBanner",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Beasts.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Blood.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Damnation.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Elements.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Faith.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Flame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Honour.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Omens.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Serenity.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Shadow.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Spirits.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Steel.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Hero-Profile-Card-Banner-Single-Wilds.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "77.12%",
              height: "11.48%",
            },
          },
        },
        position: {
          side: "BOTH",
          x: "11.39%",
          y: "5.98%",
          z: 20,
        },
      },
      {
        ref: "heroSpeedFrame",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                ],
                value: `/frames/${templateSlug}/beasts/BS-Element-Hero-Profile-Beasts-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                ],
                value: `/frames/${templateSlug}/blood/BS-Element-Hero-Profile-Blood-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                ],
                value: `/frames/${templateSlug}/damnation/BS-Element-Hero-Profile-Damnation-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                ],
                value: `/frames/${templateSlug}/elements/BS-Element-Hero-Profile-Elements-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                ],
                value: `/frames/${templateSlug}/faith/BS-Element-Hero-Profile-Faith-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                ],
                value: `/frames/${templateSlug}/flame/BS-Element-Hero-Profile-Flame-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                ],
                value: `/frames/${templateSlug}/honour/BS-Element-Hero-Profile-Honour-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                ],
                value: `/frames/${templateSlug}/omens/BS-Element-Hero-Profile-Omens-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                ],
                value: `/frames/${templateSlug}/serenity/BS-Element-Hero-Profile-Serenity-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                ],
                value: `/frames/${templateSlug}/shadow/BS-Element-Hero-Profile-Shadow-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                ],
                value: `/frames/${templateSlug}/spirits/BS-Element-Hero-Profile-Spirits-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                ],
                value: `/frames/${templateSlug}/steel/BS-Element-Hero-Profile-Steel-Speed.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                ],
                value: `/frames/${templateSlug}/wilds/BS-Element-Hero-Profile-Wilds-Speed.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "6.83%",
          y: "20.39%",
          z: 20,
        },
      },
      {
        ref: "heroResilienceFrame",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                ],
                value: `/frames/${templateSlug}/beasts/BS-Element-Hero-Profile-Beasts-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                ],
                value: `/frames/${templateSlug}/blood/BS-Element-Hero-Profile-Blood-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                ],
                value: `/frames/${templateSlug}/damnation/BS-Element-Hero-Profile-Damnation-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                ],
                value: `/frames/${templateSlug}/elements/BS-Element-Hero-Profile-Elements-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                ],
                value: `/frames/${templateSlug}/faith/BS-Element-Hero-Profile-Faith-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                ],
                value: `/frames/${templateSlug}/flame/BS-Element-Hero-Profile-Flame-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                ],
                value: `/frames/${templateSlug}/honour/BS-Element-Hero-Profile-Honour-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                ],
                value: `/frames/${templateSlug}/omens/BS-Element-Hero-Profile-Omens-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                ],
                value: `/frames/${templateSlug}/serenity/BS-Element-Hero-Profile-Serenity-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                ],
                value: `/frames/${templateSlug}/shadow/BS-Element-Hero-Profile-Shadow-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                ],
                value: `/frames/${templateSlug}/spirits/BS-Element-Hero-Profile-Spirits-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                ],
                value: `/frames/${templateSlug}/steel/BS-Element-Hero-Profile-Steel-Resilience.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                ],
                value: `/frames/${templateSlug}/wilds/BS-Element-Hero-Profile-Wilds-Resilience.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "6.83%",
          y: "29.34%",
          z: 20,
        },
      },
      {
        ref: "heroGritFrame",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                ],
                value: `/frames/${templateSlug}/beasts/BS-Element-Hero-Profile-Beasts-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                ],
                value: `/frames/${templateSlug}/blood/BS-Element-Hero-Profile-Blood-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                ],
                value: `/frames/${templateSlug}/damnation/BS-Element-Hero-Profile-Damnation-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                ],
                value: `/frames/${templateSlug}/elements/BS-Element-Hero-Profile-Elements-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                ],
                value: `/frames/${templateSlug}/faith/BS-Element-Hero-Profile-Faith-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                ],
                value: `/frames/${templateSlug}/flame/BS-Element-Hero-Profile-Flame-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                ],
                value: `/frames/${templateSlug}/honour/BS-Element-Hero-Profile-Honour-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                ],
                value: `/frames/${templateSlug}/omens/BS-Element-Hero-Profile-Omens-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                ],
                value: `/frames/${templateSlug}/serenity/BS-Element-Hero-Profile-Serenity-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                ],
                value: `/frames/${templateSlug}/shadow/BS-Element-Hero-Profile-Shadow-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                ],
                value: `/frames/${templateSlug}/spirits/BS-Element-Hero-Profile-Spirits-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                ],
                value: `/frames/${templateSlug}/steel/BS-Element-Hero-Profile-Steel-Grit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                ],
                value: `/frames/${templateSlug}/wilds/BS-Element-Hero-Profile-Wilds-Grit.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "6.83%",
          y: "38.30%",
          z: 20,
        },
      },
      {
        ref: "heroRangeFrame",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                ],
                value: `/frames/${templateSlug}/beasts/BS-Element-Hero-Profile-Beasts-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                ],
                value: `/frames/${templateSlug}/blood/BS-Element-Hero-Profile-Blood-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                ],
                value: `/frames/${templateSlug}/damnation/BS-Element-Hero-Profile-Damnation-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                ],
                value: `/frames/${templateSlug}/elements/BS-Element-Hero-Profile-Elements-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                ],
                value: `/frames/${templateSlug}/faith/BS-Element-Hero-Profile-Faith-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                ],
                value: `/frames/${templateSlug}/flame/BS-Element-Hero-Profile-Flame-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                ],
                value: `/frames/${templateSlug}/honour/BS-Element-Hero-Profile-Honour-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                ],
                value: `/frames/${templateSlug}/omens/BS-Element-Hero-Profile-Omens-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                ],
                value: `/frames/${templateSlug}/serenity/BS-Element-Hero-Profile-Serenity-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                ],
                value: `/frames/${templateSlug}/shadow/BS-Element-Hero-Profile-Shadow-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                ],
                value: `/frames/${templateSlug}/spirits/BS-Element-Hero-Profile-Spirits-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                ],
                value: `/frames/${templateSlug}/steel/BS-Element-Hero-Profile-Steel-Range.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                ],
                value: `/frames/${templateSlug}/wilds/BS-Element-Hero-Profile-Wilds-Range.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "77.71%",
          y: "20.39%",
          z: 20,
        },
      },
      {
        ref: "heroStatAttackframe",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/beasts/BS-Element-Hero-Profile-Beasts-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/beasts/BS-Element-Hero-Profile-Beasts-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/beasts/BS-Element-Hero-Profile-Beasts-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/beasts/BS-Element-Hero-Profile-Beasts-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/beasts/BS-Element-Hero-Profile-Beasts-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/blood/BS-Element-Hero-Profile-Blood-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/blood/BS-Element-Hero-Profile-Blood-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/blood/BS-Element-Hero-Profile-Blood-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/blood/BS-Element-Hero-Profile-Blood-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/blood/BS-Element-Hero-Profile-Blood-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/damnation/BS-Element-Hero-Profile-Damnation-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/damnation/BS-Element-Hero-Profile-Damnation-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/damnation/BS-Element-Hero-Profile-Damnation-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/damnation/BS-Element-Hero-Profile-Damnation-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/damnation/BS-Element-Hero-Profile-Damnation-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/elements/BS-Element-Hero-Profile-Elements-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/elements/BS-Element-Hero-Profile-Elements-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/elements/BS-Element-Hero-Profile-Elements-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/elements/BS-Element-Hero-Profile-Elements-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/elements/BS-Element-Hero-Profile-Elements-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/faith/BS-Element-Hero-Profile-Faith-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/faith/BS-Element-Hero-Profile-Faith-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/faith/BS-Element-Hero-Profile-Faith-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/faith/BS-Element-Hero-Profile-Faith-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/faith/BS-Element-Hero-Profile-Faith-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/flame/BS-Element-Hero-Profile-Flame-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/flame/BS-Element-Hero-Profile-Flame-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/flame/BS-Element-Hero-Profile-Flame-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/flame/BS-Element-Hero-Profile-Flame-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/flame/BS-Element-Hero-Profile-Flame-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/honour/BS-Element-Hero-Profile-Honour-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/honour/BS-Element-Hero-Profile-Honour-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/honour/BS-Element-Hero-Profile-Honour-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/honour/BS-Element-Hero-Profile-Honour-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/honour/BS-Element-Hero-Profile-Honour-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/omens/BS-Element-Hero-Profile-Omens-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/omens/BS-Element-Hero-Profile-Omens-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/omens/BS-Element-Hero-Profile-Omens-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/omens/BS-Element-Hero-Profile-Omens-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/omens/BS-Element-Hero-Profile-Omens-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/serenity/BS-Element-Hero-Profile-Serenity-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/serenity/BS-Element-Hero-Profile-Serenity-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/serenity/BS-Element-Hero-Profile-Serenity-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/serenity/BS-Element-Hero-Profile-Serenity-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/serenity/BS-Element-Hero-Profile-Serenity-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/shadow/BS-Element-Hero-Profile-Shadow-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/shadow/BS-Element-Hero-Profile-Shadow-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/shadow/BS-Element-Hero-Profile-Shadow-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/shadow/BS-Element-Hero-Profile-Shadow-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/shadow/BS-Element-Hero-Profile-Shadow-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/spirits/BS-Element-Hero-Profile-Spirits-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/spirits/BS-Element-Hero-Profile-Spirits-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/spirits/BS-Element-Hero-Profile-Spirits-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/spirits/BS-Element-Hero-Profile-Spirits-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/spirits/BS-Element-Hero-Profile-Spirits-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/steel/BS-Element-Hero-Profile-Steel-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/steel/BS-Element-Hero-Profile-Steel-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/steel/BS-Element-Hero-Profile-Steel-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/steel/BS-Element-Hero-Profile-Steel-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/steel/BS-Element-Hero-Profile-Steel-D12.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d4",
                  },
                ],
                value: `/frames/${templateSlug}/wilds/BS-Element-Hero-Profile-Wilds-D4.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d6",
                  },
                ],
                value: `/frames/${templateSlug}/wilds/BS-Element-Hero-Profile-Wilds-D6.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d8",
                  },
                ],
                value: `/frames/${templateSlug}/wilds/BS-Element-Hero-Profile-Wilds-D8.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d10",
                  },
                ],
                value: `/frames/${templateSlug}/wilds/BS-Element-Hero-Profile-Wilds-D10.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroStatAttackDamage",
                    value: "1d12",
                  },
                ],
                value: `/frames/${templateSlug}/wilds/BS-Element-Hero-Profile-Wilds-D12.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "77.71%",
          y: "29.35%",
          z: 20,
        },
      },
      {
        ref: "heroStatSpeed",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Speed",
            icon: `/icons/${templateSlug}/speed.svg`,
            description: "How many zones your hero can move.",
            values: [
              {
                label: "-",
                value: "-",
              },
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "17%",
          y: "21.33%",
          z: 30,
        },
      },
      {
        ref: "heroStatDefense",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Resilience",
            icon: `/icons/${templateSlug}/resilience.svg`,
            description:
              "The action roll modifier used when your hero is attacked.",
            values: [
              {
                label: "-",
                value: "-",
              },
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "17%",
          y: "30.58%",
          z: 30,
        },
      },
      {
        ref: "heroStatGrit",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Grit",
            icon: `/icons/${templateSlug}/grit.svg`,
            description:
              "If your hero has a number of wound cards equal to or higher than this number, they are defeated.",
            values: [
              {
                label: "-",
                value: "-",
              },
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "17%",
          y: "39.47%",
          z: 30,
        },
      },
      {
        ref: "heroStatAttackRange",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Range",
            icon: `/icons/${templateSlug}/range.svg`,
            description: "Your hero’s attack range.",
            values: [
              {
                label: "-",
                value: "-",
              },
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "81.36%",
          y: "21.33%",
          z: 30,
        },
      },
      {
        ref: "heroStatAttackDamage",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Damage",
            icon: `/icons/${templateSlug}/damage.svg`,
            description: "The die rolled when your hero makes a damage roll.",
            values: [
              {
                label: "1D4",
                value: "1d4",
                icon: "/dice/BS-Icons-Gold-DamageDiceD4.png",
              },
              {
                label: "1D6",
                value: "1d6",
                icon: "/dice/BS-Icons-Gold-DamageDiceD6.png",
              },
              {
                label: "1D8",
                value: "1d8",
                icon: "/dice/BS-Icons-Gold-DamageDiceD8.png",
              },
              {
                label: "1D10",
                value: "1d10",
                icon: "/dice/BS-Icons-Gold-DamageDiceD10.png",
              },
              {
                label: "1D12",
                value: "1d12",
                icon: "/dice/BS-Icons-Gold-DamageDiceD12.png",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 17,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "79.67%",
          y: "32.18%",
          z: 30,
        },
      },
      {
        ref: "heroSpecialRulesTitle",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Trait",
          },
        },
        type: "TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 18,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxLength: 20,
            },
            textShrinkSettings: {
              height: "4%",
              width: "79.17%",
              minFontSize: 14,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "10.44%",
          y: "80.18%",
          z: 30,
        },
      },
      {
        ref: "heroSpecialRules",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Trait Details",
            description:
              "Type in your trait rule here and insert icons using the selector.",
          },
        },
        type: "MULTITEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 15,
            align: "center",
            textWrapStyle: "BREAK_SPACES",
            textDrawStyle: "NORMAL",
            validation: {
              maxLength: 100,
            },
            textShrinkSettings: {
              width: "79.17%",
              height: "9.42%",
              minFontSize: 12,
            },
            useIcons: true,
          },
        },
        position: {
          side: "FRONT",
          x: "10.44%",
          y: "83.74%",
          z: 30,
        },
      },
      {
        ref: "heroImageTransparentBack",
        source: "DERIVED_VALUE",
        sourceData: {
          DERIVED_VALUE: {
            origin: {
              type: "SLOT",
              ref: "heroImageTransparent",
            },
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "0%",
          y: "28.5%",
          z: 20,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "heroImageStyle",
            value: "transparent",
          },
        ],
      },
      {
        ref: "heroImageSolidBack",
        source: "DERIVED_VALUE",
        sourceData: {
          DERIVED_VALUE: {
            origin: {
              type: "SLOT",
              ref: "heroImageSolid",
            },
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "0%",
          y: "0%",
          z: 5,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "heroImageStyle",
            value: "solid",
          },
        ],
      },
      {
        ref: "heroStartingAbilitiesHeader",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: "Starting Abilities",
          },
        },
        type: "TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 18,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            textShrinkSettings: {
              height: "4%",
              width: "57.96%",
              minFontSize: 18,
            },
          },
        },
        position: {
          side: "BACK",
          x: "21%",
          y: "16.36%",
          z: 30,
        },
      },
      {
        ref: "heroStartingAbilitiesFrame",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/SpecialRule-2.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "82.07%",
              height: "16.94%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "8.99%",
          y: "14.76%",
          z: 15,
        },
      },
      {
        ref: "heroStartingAbilities",
        source: "USER_INPUT",
        sourceData: {
          USER_INPUT: {
            label: "Starting abilities",
          },
        },
        type: "MULTITEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 15,
            align: "center",
            lineHeight: 1,
            textWrapStyle: "BREAK_SPACES",
            textDrawStyle: "NORMAL",
            validation: {
              maxLength: 100,
            },
            textShrinkSettings: {
              height: "9.51%",
              width: "57.96%",
              minFontSize: 12,
            },
          },
        },
        position: {
          side: "BACK",
          x: "21%",
          y: "19.91%",
          z: 30,
        },
      },
      {
        ref: "heroStartingEquipmentHeader",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: "Starting Equipment",
          },
        },
        type: "TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 18,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            textShrinkSettings: {
              height: "4%",
              width: "57.96%",
              minFontSize: 18,
            },
          },
        },
        position: {
          side: "BACK",
          x: "21%",
          y: "80.18%",
          z: 30,
        },
      },
      {
        ref: "heroStartingEquipment",
        source: "USER_INPUT",
        sourceData: {
          USER_INPUT: {
            label: "Starting equipment",
          },
        },
        type: "MULTITEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 15,
            align: "center",
            lineHeight: 1,
            textWrapStyle: "BREAK_SPACES",
            textDrawStyle: "NORMAL",
            validation: {
              maxLength: 100,
            },
            textShrinkSettings: {
              height: "9.24%",
              width: "75.96%",
              minFontSize: 12,
            },
          },
        },
        position: {
          side: "BACK",
          x: "12%",
          y: "83.74%",
          z: 30,
        },
      },
      {
        ref: "leftCharacteristic",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/left/BS-Element-Hero-Profile-Beasts-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/left/BS-Element-Hero-Profile-Beasts-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/left/BS-Element-Hero-Profile-Beasts-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/left/BS-Element-Hero-Profile-Beasts-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/left/BS-Element-Hero-Profile-Beasts-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/left/BS-Element-Hero-Profile-Beasts-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/left/BS-Element-Hero-Profile-Blood-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/left/BS-Element-Hero-Profile-Blood-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/left/BS-Element-Hero-Profile-Blood-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/left/BS-Element-Hero-Profile-Blood-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/left/BS-Element-Hero-Profile-Blood-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/left/BS-Element-Hero-Profile-Blood-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/left/BS-Element-Hero-Profile-Damnation-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/left/BS-Element-Hero-Profile-Damnation-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/left/BS-Element-Hero-Profile-Damnation-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/left/BS-Element-Hero-Profile-Damnation-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/left/BS-Element-Hero-Profile-Damnation-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/left/BS-Element-Hero-Profile-Damnation-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/left/BS-Element-Hero-Profile-Elements-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/left/BS-Element-Hero-Profile-Elements-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/left/BS-Element-Hero-Profile-Elements-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/left/BS-Element-Hero-Profile-Elements-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/left/BS-Element-Hero-Profile-Elements-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/left/BS-Element-Hero-Profile-Elements-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/left/BS-Element-Hero-Profile-Faith-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/left/BS-Element-Hero-Profile-Faith-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/left/BS-Element-Hero-Profile-Faith-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/left/BS-Element-Hero-Profile-Faith-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/left/BS-Element-Hero-Profile-Faith-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/left/BS-Element-Hero-Profile-Faith-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/left/BS-Element-Hero-Profile-Flame-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/left/BS-Element-Hero-Profile-Flame-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/left/BS-Element-Hero-Profile-Flame-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/left/BS-Element-Hero-Profile-Flame-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/left/BS-Element-Hero-Profile-Flame-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/left/BS-Element-Hero-Profile-Flame-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/left/BS-Element-Hero-Profile-Honour-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/left/BS-Element-Hero-Profile-Honour-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/left/BS-Element-Hero-Profile-Honour-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/left/BS-Element-Hero-Profile-Honour-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/left/BS-Element-Hero-Profile-Honour-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/left/BS-Element-Hero-Profile-Honour-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/left/BS-Element-Hero-Profile-Omens-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/left/BS-Element-Hero-Profile-Omens-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/left/BS-Element-Hero-Profile-Omens-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/left/BS-Element-Hero-Profile-Omens-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/left/BS-Element-Hero-Profile-Omens-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/left/BS-Element-Hero-Profile-Omens-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/left/BS-Element-Hero-Profile-Serenity-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/left/BS-Element-Hero-Profile-Serenity-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/left/BS-Element-Hero-Profile-Serenity-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/left/BS-Element-Hero-Profile-Serenity-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/left/BS-Element-Hero-Profile-Serenity-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/left/BS-Element-Hero-Profile-Serenity-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/left/BS-Element-Hero-Profile-Shadow-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/left/BS-Element-Hero-Profile-Shadow-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/left/BS-Element-Hero-Profile-Shadow-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/left/BS-Element-Hero-Profile-Shadow-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/left/BS-Element-Hero-Profile-Shadow-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/left/BS-Element-Hero-Profile-Shadow-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/left/BS-Element-Hero-Profile-Spirits-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/left/BS-Element-Hero-Profile-Spirits-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/left/BS-Element-Hero-Profile-Spirits-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/left/BS-Element-Hero-Profile-Spirits-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/left/BS-Element-Hero-Profile-Spirits-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/left/BS-Element-Hero-Profile-Spirits-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/left/BS-Element-Hero-Profile-Steel-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/left/BS-Element-Hero-Profile-Steel-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/left/BS-Element-Hero-Profile-Steel-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/left/BS-Element-Hero-Profile-Steel-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/left/BS-Element-Hero-Profile-Steel-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/left/BS-Element-Hero-Profile-Steel-Modifier-WIS-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/left/BS-Element-Hero-Profile-Wilds-Modifier-CHA-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/left/BS-Element-Hero-Profile-Wilds-Modifier-CON-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/left/BS-Element-Hero-Profile-Wilds-Modifier-DEX-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/left/BS-Element-Hero-Profile-Wilds-Modifier-INT-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/left/BS-Element-Hero-Profile-Wilds-Modifier-STR-Left.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicLeftType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/left/BS-Element-Hero-Profile-Wilds-Modifier-WIS-Left.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "8.97%",
          y: "27.92%",
          z: 25,
        },
      },
      {
        ref: "rightCharacteristic",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/right/BS-Element-Hero-Profile-Beasts-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/right/BS-Element-Hero-Profile-Beasts-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/right/BS-Element-Hero-Profile-Beasts-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/right/BS-Element-Hero-Profile-Beasts-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/right/BS-Element-Hero-Profile-Beasts-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "beasts",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/beasts/right/BS-Element-Hero-Profile-Beasts-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/right/BS-Element-Hero-Profile-Blood-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/right/BS-Element-Hero-Profile-Blood-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/right/BS-Element-Hero-Profile-Blood-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/right/BS-Element-Hero-Profile-Blood-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/right/BS-Element-Hero-Profile-Blood-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "blood",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/blood/right/BS-Element-Hero-Profile-Blood-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/right/BS-Element-Hero-Profile-Damnation-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/right/BS-Element-Hero-Profile-Damnation-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/right/BS-Element-Hero-Profile-Damnation-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/right/BS-Element-Hero-Profile-Damnation-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/right/BS-Element-Hero-Profile-Damnation-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "damnation",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/damnation/right/BS-Element-Hero-Profile-Damnation-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/right/BS-Element-Hero-Profile-Elements-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/right/BS-Element-Hero-Profile-Elements-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/right/BS-Element-Hero-Profile-Elements-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/right/BS-Element-Hero-Profile-Elements-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/right/BS-Element-Hero-Profile-Elements-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "elements",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/elements/right/BS-Element-Hero-Profile-Elements-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/right/BS-Element-Hero-Profile-Faith-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/right/BS-Element-Hero-Profile-Faith-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/right/BS-Element-Hero-Profile-Faith-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/right/BS-Element-Hero-Profile-Faith-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/right/BS-Element-Hero-Profile-Faith-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "faith",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/faith/right/BS-Element-Hero-Profile-Faith-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/right/BS-Element-Hero-Profile-Flame-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/right/BS-Element-Hero-Profile-Flame-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/right/BS-Element-Hero-Profile-Flame-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/right/BS-Element-Hero-Profile-Flame-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/right/BS-Element-Hero-Profile-Flame-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "flame",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/flame/right/BS-Element-Hero-Profile-Flame-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/right/BS-Element-Hero-Profile-Honour-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/right/BS-Element-Hero-Profile-Honour-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/right/BS-Element-Hero-Profile-Honour-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/right/BS-Element-Hero-Profile-Honour-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/right/BS-Element-Hero-Profile-Honour-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "honour",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/honour/right/BS-Element-Hero-Profile-Honour-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/right/BS-Element-Hero-Profile-Omens-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/right/BS-Element-Hero-Profile-Omens-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/right/BS-Element-Hero-Profile-Omens-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/right/BS-Element-Hero-Profile-Omens-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/right/BS-Element-Hero-Profile-Omens-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "omens",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/omens/right/BS-Element-Hero-Profile-Omens-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/right/BS-Element-Hero-Profile-Serenity-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/right/BS-Element-Hero-Profile-Serenity-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/right/BS-Element-Hero-Profile-Serenity-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/right/BS-Element-Hero-Profile-Serenity-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/right/BS-Element-Hero-Profile-Serenity-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "serenity",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/serenity/right/BS-Element-Hero-Profile-Serenity-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/right/BS-Element-Hero-Profile-Shadow-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/right/BS-Element-Hero-Profile-Shadow-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/right/BS-Element-Hero-Profile-Shadow-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/right/BS-Element-Hero-Profile-Shadow-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/right/BS-Element-Hero-Profile-Shadow-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "shadow",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/shadow/right/BS-Element-Hero-Profile-Shadow-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/right/BS-Element-Hero-Profile-Spirits-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/right/BS-Element-Hero-Profile-Spirits-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/right/BS-Element-Hero-Profile-Spirits-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/right/BS-Element-Hero-Profile-Spirits-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/right/BS-Element-Hero-Profile-Spirits-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "spirits",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/spirits/right/BS-Element-Hero-Profile-Spirits-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/right/BS-Element-Hero-Profile-Steel-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/right/BS-Element-Hero-Profile-Steel-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/right/BS-Element-Hero-Profile-Steel-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/right/BS-Element-Hero-Profile-Steel-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/right/BS-Element-Hero-Profile-Steel-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "steel",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/steel/right/BS-Element-Hero-Profile-Steel-Modifier-WIS-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CHA",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/right/BS-Element-Hero-Profile-Wilds-Modifier-CHA-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "CON",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/right/BS-Element-Hero-Profile-Wilds-Modifier-CON-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "DEX",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/right/BS-Element-Hero-Profile-Wilds-Modifier-DEX-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "INT",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/right/BS-Element-Hero-Profile-Wilds-Modifier-INT-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "STR",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/right/BS-Element-Hero-Profile-Wilds-Modifier-STR-Right.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "path",
                    value: "wilds",
                  },
                  {
                    type: "SLOT",
                    ref: "heroCharacteristicRightType",
                    value: "WIS",
                  },
                ],
                value: `/characteristics/${templateSlug}/wilds/right/BS-Element-Hero-Profile-Wilds-Modifier-WIS-Right.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "74.05%",
          y: "27.91%",
          z: 25,
        },
      },
      {
        ref: "heroCharacteristicLeftType",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Left Characteristic",
            values: [
              {
                label: "CHA",
                value: "CHA",
              },
              {
                label: "CON",
                value: "CON",
              },
              {
                label: "DEX",
                value: "DEX",
              },
              {
                label: "INT",
                value: "INT",
              },
              {
                label: "STR",
                value: "STR",
              },
              {
                label: "WIS",
                value: "WIS",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 0,
            align: "center",
            textDrawStyle: "NORMAL",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "BACK",
          x: "17.49%",
          y: "29.86%",
          z: 30,
        },
      },
      {
        ref: "heroCharacteristicLeft",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Left Value",
            values: [
              {
                label: "+1",
                value: "+1",
              },
              {
                label: "+2",
                value: "+2",
              },
              {
                label: "+3",
                value: "+3",
              },
              {
                label: "+4",
                value: "+4",
              },
              {
                label: "+5",
                value: "+5",
              },
              {
                label: "+6",
                value: "+6",
              },
              {
                label: "+7",
                value: "+7",
              },
              {
                label: "+8",
                value: "+8",
              },
              {
                label: "+9",
                value: "+9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 24,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "BACK",
          x: "17.49%",
          y: "29.86%",
          z: 30,
        },
      },
      {
        ref: "heroCharacteristicRightType",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Right Characteristic",
            values: [
              {
                label: "WIS",
                value: "WIS",
              },
              {
                label: "STR",
                value: "STR",
              },
              {
                label: "INT",
                value: "INT",
              },
              {
                label: "DEX",
                value: "DEX",
              },
              {
                label: "CON",
                value: "CON",
              },
              {
                label: "CHA",
                value: "CHA",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 0,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "BACK",
          x: "76.01%",
          y: "29.87%",
          z: 30,
        },
      },
      {
        ref: "heroCharacteristicRight",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Right Value",
            values: [
              {
                label: "+1",
                value: "+1",
              },
              {
                label: "+2",
                value: "+2",
              },
              {
                label: "+3",
                value: "+3",
              },
              {
                label: "+4",
                value: "+4",
              },
              {
                label: "+5",
                value: "+5",
              },
              {
                label: "+6",
                value: "+6",
              },
              {
                label: "+7",
                value: "+7",
              },
              {
                label: "+8",
                value: "+8",
              },
              {
                label: "+9",
                value: "+9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 24,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "BACK",
          x: "76.01%",
          y: "29.87%",
          z: 30,
        },
      },
    ];

    bardsungHeroTemplate = await DataStore.save(
      new GameCardTemplate({
        title: "Hero Card",
        slug: templateSlug,
        system: bardsungSystem,
        elements,
        properties,
        size: {
          width: 411.77,
          height: 562.48,
        },
        printSize: {
          width: 63,
          height: 89,
        },
        titleElement: "heroName",
        exampleCardImage: "/examples/bardsungHero.jpg",
      })
    );
  } else {
    console.debug("found Bardsung Hero Card template, skipping creation");
  }
};

export const defaultHeroCardProperties = [
  { propertyRef: "path", value: "beasts" },
  { propertyRef: "heroImageStyle", value: "transparent" },
];

export const defaultHeroCardElements = [
  { elementRef: "heroStatSpeed", value: "-" },
  { elementRef: "heroStatDefense", value: "-" },
  { elementRef: "heroStatGrit", value: "-" },
  { elementRef: "heroStatAttackRange", value: "-" },
  { elementRef: "heroStatAttackDamage", value: "1d4" },
  { elementRef: "heroCharacteristicLeftType", value: "CHA" },
  { elementRef: "heroCharacteristicLeft", value: "+1" },
  { elementRef: "heroCharacteristicRightType", value: "WIS" },
  { elementRef: "heroCharacteristicRight", value: "+1" },
];
