import { useEffect, useRef, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Popover,
} from "@mui/material";
import {
  findSlotForCardAndElement,
  upsertCardSlotById,
} from "../../../services/card";
import { EditorTitle } from "../EditorTitle";
import { IconPicker } from "./texticons/IconPicker";
import { IconPickerButton } from "./texticons/IconPickerButton";
import { ElementEditorProps } from "./types";
import { textLengthWithIcons } from "../../../services/text";

export const MultiText: React.FC<ElementEditorProps> = ({ element, card }) => {
  const textFieldRef = useRef<HTMLInputElement>();
  const [value, setValue] = useState<string>(
    findSlotForCardAndElement(card, element.ref)?.value || ""
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // TODO: don't update on every keypress, maybe wait between them?
    upsertCardSlotById(card, element.ref, value);
  }, [value]);

  const { label, description } = element.sourceData.USER_INPUT!;
  const maxLength = element.typeData.TEXT!.validation?.maxLength || 999;
  const useIcons = element.typeData.TEXT?.useIcons;

  return (
    <Grid item container xs={12}>
      <Grid item container>
        <EditorTitle title={label} toolTipText={description} />
      </Grid>

      <FormControl fullWidth>
        <OutlinedInput
          multiline
          fullWidth
          value={value}
          inputProps={{ maxLength }}
          rows={4}
          onChange={(e) => setValue(e.target.value)}
          inputRef={textFieldRef}
          endAdornment={useIcons && <IconPickerButton onClick={handleClick} />}
        />
        <FormHelperText>{`${textLengthWithIcons(
          value
        )}/${maxLength}`}</FormHelperText>
      </FormControl>

      {useIcons && (
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <IconPicker
            setValue={setValue}
            textFieldRef={textFieldRef}
            handleClose={handleClose}
          />
        </Popover>
      )}
    </Grid>
  );
};
