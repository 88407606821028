import ReactGA from "react-ga4";

export const initGlobals = () => {
  window.onRunOptanonWrapper = () => {
    const buttons = document.querySelectorAll(
      "#onetrust-accept-btn-handler,#accept-recommended-btn-handler,.save-preference-btn-handler,.onetrust-close-btn-handler,.button-theme"
    );
    buttons.forEach((b) =>
      b.addEventListener("click", () => window.location.reload())
    );

    const loadGA = () => {
      const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
      if (GA_TRACKING_ID) ReactGA.initialize(GA_TRACKING_ID);
    };

    window.OneTrust.InsertScript(
      "/dummy.js",
      "head",
      loadGA,
      {},
      "C0004" // Targeting cookies category
    );
  };
};
