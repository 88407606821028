import { Container, Grid, Box, Stack } from "@mui/material";
import SFGLogo from "../../icons/sfg.svg";

export const Header: React.FC = () => {
  return (
    <Grid item xs={12} bgcolor="#171717">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <a href="https://steamforged.com/">
            <Box
              component="img"
              src={SFGLogo}
              alt="Steamforged Games"
              height={40}
              pt={1}
            />
          </a>
        </Stack>
      </Container>
    </Grid>
  );
};
