import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DataStore } from "@aws-amplify/datastore";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { GameCardTemplate } from "../models";
import { SessionContextData, SessionContext } from "../context/SessionContext";
import { useGAPageView } from "../hooks/gaPageView";

export const Library: React.FC = () => {
  const { loading } = useContext<SessionContextData>(SessionContext);
  const { systemSlug } = useParams();
  const [templates, setTemplates] = useState<GameCardTemplate[]>();
  // const [cards, setCards] = useState<GameCard[]>();

  useGAPageView("Library");

  useEffect(() => {
    const getTemplates = async () => {
      const tmpls = (await DataStore.query(GameCardTemplate)).filter(
        (t) => t.system?.slug === systemSlug
      );

      //   const crds = (await DataStore.query(GameCard))
      //   .filter(
      //     (c) => c.templateID && tmpls.map((t) => t.id).includes(c.templateID)
      //   )
      //   .map((c) => ({
      //     ...c,
      //     template: tmpls.find((t) => t.id === c.templateID),
      //   }));
      // setCards(crds);

      setTemplates(tmpls);
    };

    getTemplates();
  }, [loading]);

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card
            elevation={6}
            sx={{
              mx: "auto",
            }}
          >
            <CardHeader title="Create a new card" />
            <CardContent>
              <Typography variant="body1">
                Choose a card type to get started:
              </Typography>
            </CardContent>
            <CardContent>
              <Grid container spacing={2} justifyContent="center">
                {templates?.map(({ id, title, slug, exampleCardImage }) => (
                  <Grid item key={id}>
                    <Stack spacing="5px">
                      <a href={`/${systemSlug}/library/${slug}/cards/new`}>
                        <img
                          src={`${exampleCardImage}`}
                          alt={`Example ${title}.`}
                          style={{ borderRadius: "10px", width: "200px" }}
                        />
                      </a>
                      <Button
                        startIcon={<Add />}
                        href={`/${systemSlug}/library/${slug}/cards/new`}
                        variant="contained"
                      >
                        {title}
                      </Button>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} md={6}></Grid>
        <Card
          elevation={6}
          sx={{
            mx: "auto",
          }}
        >
          <CardHeader title="Browse your library" />
          <CardContent>
            {!!cards?.length ? (
              <>
                <Typography variant="body1">
                  Here's some cards we found that you've created on this device
                  before.
                </Typography>
                <ul>
                  {cards?.map((c) => (
                    <li key={c.id}>
                      <a
                        href={`/${systemSlug}/library/${c.template?.slug}/cards/${c.id}`}
                      >
                        {findSlotForCardAndElement(c, c.template?.titleElement!)
                          ?.value || "Unamed Card"}{" "}
                        ({c.template?.title})
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <Typography variant="body1">
                We didn't find any cards saved on your device. Make a new card
                to get started!
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid> */}
      </Grid>
    </Container>
  );
};
