import { findCardPropValByRef, findSlotForCardAndElement } from "./card";
import {
  DerivedCloneElementOrigin,
  ElementConditionType,
  ElementCondition,
  GameCard,
  GameCardSlot,
  PropertyValue,
} from "../models";

type ElementConditionFinderRegistry = {
  [key in ElementConditionType]: (
    card: GameCard,
    ref: string
  ) => GameCardSlot | PropertyValue | undefined;
};

const elementOriginFinderRegistry: ElementConditionFinderRegistry = {
  PROPERTY: (card, ref) => findCardPropValByRef(card, ref),
  SLOT: (card, ref) => findSlotForCardAndElement(card, ref),
};

export const findOrigin = (
  card: GameCard,
  { type, ref }: DerivedCloneElementOrigin | ElementCondition
) => elementOriginFinderRegistry[type](card, ref);

export const doesCardMeetConditions = (
  card: GameCard,
  conditions: ElementCondition[]
) =>
  conditions.every(
    (condition) => findOrigin(card, condition)?.value === condition.value
  );
