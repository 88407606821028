import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { SessionContextData, SessionContext } from "../context/SessionContext";

export const Home: React.FC = () => {
  const { loading, gameSystem, template, card } =
    useContext<SessionContextData>(SessionContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;

    if (gameSystem) {
      // navigate to template selector
      return navigate(`/${gameSystem.slug}/library/`);
    }
  }, [card, template, gameSystem, loading, navigate]);

  if (loading) return <CircularProgress />;

  return <div>TODO: Select system</div>;
};
