import { useContext } from "react";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { Grid } from "@mui/material";
import {
  SessionContextData,
  SessionContext,
} from "../../../../context/SessionContext";
import { CustomEmoji } from "emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index";
import { textLengthWithIcons } from "../../../../services/text";

type IconPickerProps = {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  textFieldRef: React.MutableRefObject<HTMLInputElement | undefined>;
  handleClose: () => void;
};

export const IconPicker: React.FC<IconPickerProps> = ({
  setValue,
  textFieldRef,
  handleClose,
}) => {
  const { template } = useContext<SessionContextData>(SessionContext);

  if (!template?.system?.icons?.length) return <></>;

  const customEmojis: CustomEmoji[] = template.system.icons.map(
    ({ label, shortCode, iconPath }) => ({
      name: label,
      short_names: [shortCode],
      imageUrl: iconPath,
    })
  );

  const insertIcon = (icon: CustomEmoji) => {
    const currentIndex = textFieldRef?.current?.selectionStart ?? 0;
    const maxLength = textFieldRef?.current?.maxLength;
    setValue((v) => {
      if (!maxLength || textLengthWithIcons(v) < maxLength)
        return `${v.slice(0, currentIndex)}${icon.colons}${v.slice(
          currentIndex
        )}`;
      return v;
    });
    handleClose();
  };

  return (
    <Grid item>
      <Picker
        title="Pick your icons"
        onSelect={insertIcon}
        custom={customEmojis}
        include={["custom"]}
        showSkinTones={false}
        emoji=""
      />
    </Grid>
  );
};
