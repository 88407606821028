import { Grid, InputBase, NativeSelect, styled } from "@mui/material";
import { useEffect, useState } from "react";
import {
  findSlotForCardAndElement,
  upsertCardSlotById,
} from "../../../services/card";
import { ElementEditorProps } from "./types";
import { EditorTitle } from "../EditorTitle";

export const Select: React.FC<ElementEditorProps> = ({ element, card }) => {
  const [value, setValue] = useState<string>(
    findSlotForCardAndElement(card, element.ref)?.value || ""
  );

  useEffect(() => {
    // TODO: don't update on every keypress, maybe wait between them?
    upsertCardSlotById(card, element.ref, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const BootstrapInput = styled(InputBase)(
    ({ theme: { spacing, palette, transitions } }) => ({
      "label + &": {
        marginTop: spacing(3),
      },
      "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: spacing(1.25, 4.25, 1.25, 1.5),
        transition: transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          borderRadius: 4,
          borderColor: "#80bdff",
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
      },
    })
  );

  const { label, icon, values, description } = element.sourceData.USER_INPUT!;

  return (
    <Grid item xs={6} md={5} lg={4}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <EditorTitle title={label} toolTipText={description} />
          </Grid>
        </Grid>
        {icon && (
          <Grid item xs={4}>
            <img
              src={icon}
              height={40}
              width={40}
              alt=""
              style={{ display: "block" }}
            />
          </Grid>
        )}
        <Grid item xs={8}>
          <NativeSelect
            fullWidth
            onChange={(e) => setValue(e.target.value)}
            input={<BootstrapInput />}
            value={value}
          >
            {values?.map((v) => {
              return (
                <option value={v.value} key={`key-${v.value}`}>
                  {v.label}
                </option>
              );
            })}
          </NativeSelect>
        </Grid>
      </Grid>
    </Grid>
  );
};
