import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Flip } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DataStore } from "@aws-amplify/datastore";
import { useNavigate, useParams } from "react-router-dom";
import { CardSide, GameCard, GameCardTemplate } from "../models";
import Editor from "../components/edit/Editor";
import Preview from "../components/preview/Preview";
import { SessionContextData, SessionContext } from "../context/SessionContext";
import { DownloadCard } from "../components/buttons/DownloadCard";
import { useTheme } from "@mui/material/styles";
import { FullScreenDialog } from "./FullScreenDialog";
import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import {
  cardDefaultElementRegistry,
  templateSlugs,
  cardDefaultPropertyRegistry,
} from "../services/initialiseData";
import { useGAPageView } from "../hooks/gaPageView";

export const CreateEditCard: React.FC = () => {
  const { card, setCard, template, setTemplate, loading } =
    useContext<SessionContextData>(SessionContext);
  const { templateSlug, cardId } = useParams();
  const [cardSide, setCardSide] = useState<CardSide>(CardSide.FRONT);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const phoneDisplay = useMediaQuery(theme.breakpoints.down("sm"));

  useGAPageView("New Card");

  useEffect(() => {
    const getCardById = async () => {
      const tmpl = (await DataStore.query(GameCardTemplate)).find(
        (t) => t.slug === templateSlug
      );
      if (!tmpl) return;
      setTemplate(tmpl);

      if (!cardId && card) return;

      if (!cardId && !card) {
        const newCard = await DataStore.save(
          // FIXME: why do we need both the template obj & ID?
          new GameCard({
            templateID: tmpl.id,
            slots: cardDefaultElementRegistry[tmpl.slug as templateSlugs],
            propertyValues:
              cardDefaultPropertyRegistry[tmpl.slug as templateSlugs],
          })
        );

        return navigate(
          `/${tmpl.system?.slug}/library/${tmpl.slug}/cards/${newCard.id}`,
          { replace: true }
        );
      }

      if (!cardId) return;

      const cardById = await DataStore.query(GameCard, cardId);
      if (!cardById)
        return navigate(`/${tmpl.system?.slug}/library/${tmpl.slug}/cards/new`);

      setCard(cardById);
    };

    getCardById();
  }, [cardId, loading]);

  if (!template) return <div>No template selected</div>;
  if (!card) return <div>No card selected</div>;

  const { system, title: templateTitle } = template;
  const { title: systemTitle, slug: systemSlug } = system!;

  const onFlipCard = () =>
    setCardSide((s) => (s === CardSide.FRONT ? CardSide.BACK : CardSide.FRONT));

  return (
    <Container>
      <Grid
        container
        columnSpacing={{ sm: 2, md: 4, lg: 10 }}
        sx={{
          justifyContent: "center",
        }}
        spacing={4}
      >
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Button
              startIcon={<ArrowBackIcon />}
              href={`/${systemSlug}/library`}
            >
              Back to Library
            </Button>
            <Typography
              sx={{ display: { xs: "none", md: "block" } }}
              variant="h5"
              component="h2"
              color="white"
            >
              Create a custom{" "}
              <Box fontWeight={600} component="span">
                {templateTitle}
              </Box>{" "}
              for{" "}
              <Box fontWeight={600} component="span">
                {systemTitle}
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={7} order={{ xs: 2, sm: 1 }}>
          <Editor selectedSide={cardSide} />
        </Grid>
        <Grid item xs={12} sm={6} lg={5} order={{ xs: 1, sm: 2 }}>
          <Stack direction="column" spacing={2} sx={{ position: "sticky", top: "10px" }}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <Button
                startIcon={<Flip />}
                variant="contained"
                onClick={onFlipCard}
              >
                Flip card
              </Button>
              <DownloadCard variant="button" />
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ display: { sm: "none" } }}
            >
              <Button
                startIcon={<Flip />}
                variant="contained"
                onClick={onFlipCard}
              >
                Flip card
              </Button>
            </Stack>
            {!phoneDisplay && <Preview selectedSide={cardSide} />}
          </Stack>
        </Grid>
      </Grid>

      {phoneDisplay && (
        <>
          <Fab
            color="primary"
            sx={{
              margin: 0,
              top: "auto",
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
            }}
            onClick={() => setOpen(true)}
          >
            <CropPortraitIcon />
          </Fab>
          <FullScreenDialog
            open={open}
            setOpen={setOpen}
            onFlipCard={onFlipCard}
          >
            <Preview selectedSide={cardSide} />
          </FullScreenDialog>
        </>
      )}
    </Container>
  );
};
