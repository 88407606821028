import { Dialog, Fab, Grid, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { DownloadCard } from "../components/buttons/DownloadCard";
import CloseIcon from "@mui/icons-material/Close";
import { Flip } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type FullScreenDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onFlipCard: () => void;
};

export const FullScreenDialog: React.FC<FullScreenDialogProps> = ({
  open,
  setOpen,
  onFlipCard,
  children,
}) => {
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            backgroundColor: "rgb(0 0 0 / 60%)",
          }
        }}
      >
        {children}
        <Grid
          container
          spacing={2}
          justifyContent="space-evenly"
          sx={{
            top: "auto",
            right: "auto",
            bottom: 20,
            left: "auto",
            position: "fixed",
          }}
        >
          <Grid item>
            <Fab color="primary" onClick={onFlipCard}>
              <Flip />
            </Fab>
          </Grid>
          <Grid item>
            <DownloadCard variant="fab" />
          </Grid>
          <Grid item>
            <Fab color="primary" onClick={() => setOpen(false)}>
              <CloseIcon />
            </Fab>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};
