import { useContext } from "react";
import { SessionContextData, SessionContext } from "../context/SessionContext";
import { useWindowDimensions } from "./window";

export const useScalingFactor = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { cardFrontDomRef, template } =
    useContext<SessionContextData>(SessionContext);

  if (!template?.size.width) return 1;

  const {
    size: { width },
  } = template;

  if (!cardFrontDomRef?.current?.offsetWidth) return screenWidth / width;

  const {
    current: { offsetWidth },
  } = cardFrontDomRef;

  return offsetWidth / width;
};
