import { useContext, useEffect, useRef } from "react";
import {
  SessionContextData,
  SessionContext,
} from "../../../context/SessionContext";
import { interpolateIconsForSystem } from "../../../services/textIcons";
import { dataProducerRegistry } from "../../registry/data";
import { ElementProps, TextWrapStyleMappings } from "./types";
import { TextWrapStyleType } from "../../../models";
import { shrinkFontToFit } from "../../../services/text";
import { useScalingFactor } from "../../../hooks/scaling";
import { calcPosition } from "../../../services/position";

export const textWrapStyleMap: TextWrapStyleMappings = {
  NORMAL: "normal",
  PRE: "pre",
  NOWRAP: "nowrap",
  PRE_WRAP: "pre-wrap",
  PRE_LINE: "pre-line",
  BREAK_SPACES: "break-spaces",
};

export const TextElement: React.FC<ElementProps> = ({ card, element }) => {
  const { template } = useContext<SessionContextData>(SessionContext);
  const textScale = useScalingFactor();
  const currentValue = dataProducerRegistry[element.source](element, card);

  const {
    padding,
    color,
    font,
    fontSize,
    align,
    textWrapStyle,
    textDrawStyle,
    textShrinkSettings,
    lineHeight,
  } = element.typeData.TEXT!;
  const {
    position: { z },
  } = element!;
  const containerRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!containerRef?.current || !textShrinkSettings) return;
    shrinkFontToFit(
      containerRef.current,
      textShrinkSettings.minFontSize,
      fontSize,
      textScale
    );
  }, [containerRef, card]);

  if (!currentValue) return <></>;

  let containerStyles: React.CSSProperties = {
    position: "absolute",
    overflow: "hidden",
    left: calcPosition("x", element.position, element.displacedByRefs, card),
    top: calcPosition("y", element.position, element.displacedByRefs, card),
    width: textShrinkSettings?.width,
    height: textShrinkSettings?.height,
    zIndex: z,
    textAlign: align as any,
    padding: padding ? padding : undefined,
    lineHeight: lineHeight || 1.2 * textScale,
  };

  /*TODO improvement. Elements that have textShrinkSettings cannot have FontSize set as it will change
   how shrinkFontToFit evaluates between desktop and mobile and will look different. Elements that do 
   not have textShrinkSettings must have a fontsize set as they will not scale correctly between mobile and desktop without it  */
  if (!textShrinkSettings) {
    containerStyles = {
      ...containerStyles,
      fontSize: Math.round(fontSize * textScale),
    };
  }

  const textStyles: React.CSSProperties = {
    fontFamily: font,
    color,
    whiteSpace: textWrapStyleMap[textWrapStyle || TextWrapStyleType.NORMAL],
  };

  if (textDrawStyle === "WITH_STROKE") {
    textStyles.WebkitTextStroke = `0.5px ${color}`;
  }

  const markup = interpolateIconsForSystem(
    currentValue,
    template!.system!,
    fontSize
  );

  return (
    <div style={containerStyles}>
      <span ref={containerRef} style={textStyles}>
        {markup}
      </span>
    </div>
  );
};
