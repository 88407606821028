// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CardSide = {
  "FRONT": "FRONT",
  "BACK": "BACK",
  "BOTH": "BOTH"
};

const ElementSource = {
  "CONSTANT": "CONSTANT",
  "DERIVED_VALUE": "DERIVED_VALUE",
  "DERIVED_LOOKUP": "DERIVED_LOOKUP",
  "USER_INPUT": "USER_INPUT"
};

const ElementConditionType = {
  "PROPERTY": "PROPERTY",
  "SLOT": "SLOT"
};

const ElementType = {
  "TEXT": "TEXT",
  "MULTITEXT": "MULTITEXT",
  "IMAGE": "IMAGE",
  "SELECT_TEXT": "SELECT_TEXT",
  "SELECT_IMAGE": "SELECT_IMAGE",
  "SWITCH": "SWITCH"
};

const TextWrapStyleType = {
  "NORMAL": "NORMAL",
  "PRE": "PRE",
  "NOWRAP": "NOWRAP",
  "PRE_WRAP": "PRE_WRAP",
  "PRE_LINE": "PRE_LINE",
  "BREAK_SPACES": "BREAK_SPACES"
};

const DrawStyleType = {
  "NORMAL": "NORMAL",
  "WITH_STROKE": "WITH_STROKE"
};

const PropertyEditorType = {
  "SWATCH_PICKER": "SWATCH_PICKER",
  "RADIO": "RADIO"
};

const { GameSystem, GameCardTemplate, GameCard, GameSystemIcon, TemplateElement, ElementPosition, ElementSourceData, ConstantElementData, DerivedLookupElementData, DerivedLookupElementEntry, ElementCondition, DerivedCloneElementData, DerivedCloneElementOrigin, UserInputElementData, UserSelectElementEntry, ElementTypeData, ImageElementData, PercentageSize, TextElementData, TextShrinkSettings, ElementValidation, DisplaceCoordinates, TemplateProperty, TemplatePropertyValue, TemplatePVEditorTypeData, SwatchPickerData, Size, SwatchPickerOption, GameCardSlot, PropertyValue } = initSchema(schema);

export {
  GameSystem,
  GameCardTemplate,
  GameCard,
  CardSide,
  ElementSource,
  ElementConditionType,
  ElementType,
  TextWrapStyleType,
  DrawStyleType,
  PropertyEditorType,
  GameSystemIcon,
  TemplateElement,
  ElementPosition,
  ElementSourceData,
  ConstantElementData,
  DerivedLookupElementData,
  DerivedLookupElementEntry,
  ElementCondition,
  DerivedCloneElementData,
  DerivedCloneElementOrigin,
  UserInputElementData,
  UserSelectElementEntry,
  ElementTypeData,
  ImageElementData,
  PercentageSize,
  TextElementData,
  TextShrinkSettings,
  ElementValidation,
  DisplaceCoordinates,
  TemplateProperty,
  TemplatePropertyValue,
  TemplatePVEditorTypeData,
  SwatchPickerData,
  Size,
  SwatchPickerOption,
  GameCardSlot,
  PropertyValue
};