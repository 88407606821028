import React, { useEffect } from "react";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";
import { SessionContextProvider } from "./context/SessionContext";
import { AppRouter } from "./routing/AppRouter";
import { bardsungTheme } from "./theme/bardsung";
import { Frame } from "./components/common/Frame";
import { initGlobals } from "./services/globals";

const App: React.FC = () => {
  useEffect(() => {
    initGlobals();
  }, []);

  return (
    <BrowserRouter>
      <SessionContextProvider>
        <ThemeProvider theme={bardsungTheme}>
          <Frame>
            <AppRouter />
          </Frame>
        </ThemeProvider>
      </SessionContextProvider>
    </BrowserRouter>
  );
};

export default App;
