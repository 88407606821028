import { useContext } from "react";
import {
  SessionContextData,
  SessionContext,
} from "../../context/SessionContext";
import { CardSide } from "../../models";
import { doesCardMeetConditions } from "../../services/conditions";
import { useScalingFactor } from "../../hooks/scaling";
import { elementRenderers } from "../registry/renderers";

type DOMPreviewProps = {
  side: CardSide.FRONT | CardSide.BACK;
  domRef: React.RefObject<HTMLDivElement> | undefined;
};

export const DOMPreview: React.FC<DOMPreviewProps> = ({ side, domRef }) => {
  const { card } = useContext<SessionContextData>(SessionContext);
  const scalingFactor = useScalingFactor();

  if (!card?.template) return <></>;

  const { template } = card;

  const {
    size: { width, height },
    elements,
  } = template;

  const elementsToRender = elements
    .filter(({ position: { side: s } }) => s === side || s === CardSide.BOTH)
    .filter(
      ({ renderConditions }) =>
        !renderConditions?.length ||
        doesCardMeetConditions(card, renderConditions)
    );

  const domRendererStyles: React.CSSProperties = {
    width: "auto",
    margin: "auto",
    overflow: "hidden",
    height: scalingFactor * height,
    maxHeight: height,
    maxWidth: width,
    position: "relative",
    backfaceVisibility: "hidden",
    borderRadius: "15px",
    ...(side === CardSide.FRONT
      ? {
          zIndex: 5,
          transform: "rotateY(0deg)",
        }
      : {
          transform: "rotateY(180deg)",
          top: -height * scalingFactor,
        }),
  };

  return (
    <div style={domRendererStyles} ref={domRef} id={side}>
      {elementsToRender.map((element) => {
        const Element = elementRenderers[element.type];
        return <Element key={element.ref} card={card} element={element} />;
      })}
    </div>
  );
};
