export const storeImage = async (file: File) => {
  const url = URL.createObjectURL(file);
  const path = buildLocalStorageFilePath(file.name);
  localStorage.setItem(path, url);
  return path;
};

const buildLocalStorageFilePath = (fileName: string) => {
  return `${localStorageFilePrefix}${fileName}`;
};

export const localStorageFilePrefix = "localstorage://";
