import { DataStore } from "aws-amplify";

export const setupSubscriptionsForModel = (
  modelType: "GameCard",
  model: any,
  instance: any,
  setInstance: any,
  currentId: string | undefined,
  setCurrentId: React.Dispatch<React.SetStateAction<string | undefined>>,
  subscription: any,
  setSubscription: any
) => {
  if (!instance) {
    setCurrentId(undefined);

    if (subscription) {
      console.debug(
        `Current ${modelType} set to ${instance}, clearing subscription`
      );
      subscription.unsubscribe();
      setSubscription(undefined);
    }

    return;
  }

  const { id: instanceId } = instance;
  if (instanceId === currentId) {
    console.debug(
      `Current ${modelType} hasn't changed, skipping subscription setup`
    );
    return;
  }
  setCurrentId(instanceId);

  console.debug(
    `Current ${modelType} changed, setting up subscription to changes for instance  ${instanceId}`
  );

  if (subscription) {
    console.debug(`Subscription already exists for ${modelType}, clearing it`);
    subscription.unsubscribe();
  }

  const sub = DataStore.observe(model, instanceId).subscribe(async (msg) => {
    console.debug(`Observed change for ${modelType} ${instanceId}`);
    console.debug(msg);

    console.debug("Getting latest element version from DataStore");
    const latest = await DataStore.query(model, instanceId);
    console.debug(latest);
    setInstance(latest);
  });
  setSubscription(sub);

  console.debug(`Subscription created for ${modelType}`);
  console.debug(sub);
};
