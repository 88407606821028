import { ElementType } from "../../models";
import { Select, Image, MultiText, Text, Switch } from "./elements";
import { ElementEditorProps } from "./elements/types";

type ElementTypeRegistry = {
  [key in ElementType]: React.FC<ElementEditorProps>;
};

const registry: ElementTypeRegistry = {
  TEXT: Text,
  MULTITEXT: MultiText,
  IMAGE: Image,
  SELECT_TEXT: Select,
  SELECT_IMAGE: Select,
  SWITCH: Switch,
};

const ElementEditor: React.FC<ElementEditorProps> = ({ element, card }) => {
  const TypedElementEditor = registry[element.type];

  return <TypedElementEditor element={element} card={card} />;
};

export default ElementEditor;
