import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { upsertCardSlotById } from "../../../services/card";
import { storeImage } from "../../../services/storage";
import { ElementEditorProps } from "./types";
import { ErrorAlert } from "./ErrorAlert";
import { EditorTitle } from "../EditorTitle";

export const Image: React.FC<ElementEditorProps> = ({ element, card }) => {
  const [error, setError] = useState<Error>();
  const onClick =
    () =>
    async ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const filePath = await storeImage(files![0]);
        upsertCardSlotById(card, element.ref, filePath);
      } catch (error) {
        console.error(error);
        const newError = new Error(
          "Something's not quite right with the uploaded image. Please ensure it's a PNG or JPG file under 10 MB. Images with transparent backgrounds cropped to fit just right on a card will work best."
        );
        setError(newError);
      }
    };

  const Input = styled("input")({
    display: "none",
  });

  const { label, description } = element.sourceData.USER_INPUT!;

  return (
    <Grid item xs={12}>
      <Grid container>
        <EditorTitle title={label} toolTipText={description} />
      </Grid>
      <label htmlFor="contained-button-file">
        <Input
          accept="image/*"
          id="contained-button-file"
          data-testid="label-upload"
          multiple
          type="file"
          onChange={onClick()}
        />
        <Button
          data-testid="button-upload"
          variant="contained"
          component="span"
          fullWidth
        >
          Upload
        </Button>
      </label>
      <ErrorAlert error={error} setError={setError}></ErrorAlert>
    </Grid>
  );
};
