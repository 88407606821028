import { iconRegex } from "../constants";

const isOverflowing = ({ clientHeight, scrollHeight }: HTMLElement) => {
  return scrollHeight > clientHeight;
};

export const shrinkFontToFit = (
  element: HTMLElement,
  minFontSize: number,
  maxFontSize: number,
  textScale: number
) => {
  const parent = element.parentElement;
  if (!parent) return;
  maxFontSize = Math.round(maxFontSize * textScale);
  const step = 1 * textScale;
  let i = Math.round(minFontSize * textScale);
  let overflow = isOverflowing(parent);

  while (!overflow && i < maxFontSize) {
    element.style.fontSize = `${i}px`;
    overflow = isOverflowing(parent);
    if (!overflow) i += step;
  }
  element.style.fontSize = `${i - step}px`; // Step back from the overflow cliff edge.
};

export const textLengthWithIcons = (text: string) => {
  const modifiedText = text.replaceAll(iconRegex, " ");
  return modifiedText.length;
};
