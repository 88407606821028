import React from "react";
import { Grid, Typography } from "@mui/material";
import { EditorToolTip } from "./EditorToolTip";

type EditorTitleProps = {
  title: string;
  toolTipText: string | undefined | null;
};

export const EditorTitle: React.FC<EditorTitleProps> = ({
  title,
  toolTipText,
}) => {
  return (
    <>
      <Grid
        item
        container
        xs={10}
        sx={{
          alignContent: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant="subtitle1">{title}</Typography>
      </Grid>
      <Grid
        item
        container
        xs={2}
        sx={{
          alignContent: "center",
          justifyContent: "flex-end",
        }}
      >
        <EditorToolTip description={toolTipText} />
      </Grid>
    </>
  );
};
