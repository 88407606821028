import { PropertyEditorProps } from "./properties/types";
import { SwatchEditor } from "./properties/SwatchEditor";
import { PropertyEditorType } from "../../models";
import { RadioEditor } from "./properties/RadioEditor";

type PropertyEditorTypeRegistry = {
  [key in PropertyEditorType]: React.FC<PropertyEditorProps>;
};

const registry: PropertyEditorTypeRegistry = {
  SWATCH_PICKER: SwatchEditor,
  RADIO: RadioEditor,
};

export const PropertyEditor: React.FC<PropertyEditorProps> = ({
  property,
  card,
}) => {
  const { editorType } = property;

  const Editor = registry[editorType];

  return <Editor card={card} property={property} />;
};
