import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const useGAPageView = (title: string) => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_GA_TRACKING_ID) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
        title: title,
      });
    }
  }, []);
};
