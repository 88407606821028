import { Grid, Typography, Switch as MuiSwitch } from "@mui/material";
import { useEffect, useState } from "react";
import {
  findSlotForCardAndElement,
  upsertCardSlotById,
} from "../../../services/card";
import { ElementEditorProps } from "./types";

export const Switch: React.FC<ElementEditorProps> = ({ card, element }) => {
  const [value, setValue] = useState<string>(
    findSlotForCardAndElement(card, element.ref)?.value || ""
  );
  useEffect(() => {
    upsertCardSlotById(card, element.ref, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked === true ? "true" : "");
  };
  const { label } = element.sourceData.USER_INPUT!;

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        alignContent: "center",
        justifyContent: "flex-start",
        alignItems: "baseline",
      }}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <MuiSwitch
        checked={value === "true" || false}
        onChange={handleChange}
      ></MuiSwitch>
    </Grid>
  );
};
