import React, { useContext } from "react";
import {
  SessionContextData,
  SessionContext,
} from "../../context/SessionContext";
import { useScalingFactor } from "../../hooks/scaling";
import { CardSide } from "../../models";
import { DOMPreview } from "./DOMPreview";

type PreviewProps = {
  selectedSide: CardSide;
};

const Preview: React.FC<PreviewProps> = ({ selectedSide }) => {
  const { card, cardFrontDomRef, cardBackDomRef } =
    useContext<SessionContextData>(SessionContext);
    const scalingFactor = useScalingFactor();
  
    if (!card?.template) return <></>;
    const { template } = card;
  
    const {
      size: { height },
    } = template;

  const innerContainerStyles: React.CSSProperties = {
    height: scalingFactor * height,
    transition: "0.8s",
    transformStyle: "preserve-3d",
    ...(selectedSide === CardSide.FRONT
      ? {}
      : {
          transform: "rotateY(180deg)",
        }),
  };

  return (
    <div style={innerContainerStyles}>
      <DOMPreview side={CardSide.FRONT} domRef={cardFrontDomRef} />
      <DOMPreview side={CardSide.BACK} domRef={cardBackDomRef} />
    </div>
  );
};

export default Preview;
