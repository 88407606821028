import { ArrowDropDown, Download } from "@mui/icons-material";
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Button,
  Fab,
} from "@mui/material";
import React, { useState, useRef, useContext } from "react";
import {
  SessionContextData,
  SessionContext,
} from "../../context/SessionContext";
import { CardSide } from "../../models";
import { downloadCardPdf, downloadCardSide } from "../../services/download";
import { gaEvent } from "../../services/gaEvent";

type DownloadCardProps = {
  variant: "button" | "fab";
};

export const DownloadCard: React.FC<DownloadCardProps> = ({ variant }) => {
  const { template, cardFrontDomRef, cardBackDomRef } =
    useContext<SessionContextData>(SessionContext);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleDownloadSide =
    (
      domRef: React.RefObject<HTMLDivElement> | undefined,
      side: CardSide.FRONT | CardSide.BACK
    ) =>
    () => {
      if (!domRef?.current) return;
      downloadCardSide(domRef.current, side);
      setOpen(false);
      gaEvent("Card Download", `Download ${side}`);
    };

  const handleDownloadPdf = () => {
    if (!cardFrontDomRef?.current || !cardBackDomRef?.current || !template)
      return;
    downloadCardPdf(
      cardFrontDomRef.current,
      cardBackDomRef.current,
      template.printSize
    );
    setOpen(false);
    gaEvent("Card Download", `Download PDF`);
  };

  const options = [
    {
      label: "Download card (PDF)",
      action: handleDownloadPdf,
    },
    {
      label: "Download front (PNG)",
      action: handleDownloadSide(cardFrontDomRef, CardSide.FRONT),
    },
    {
      label: "Download back (PNG)",
      action: handleDownloadSide(cardBackDomRef, CardSide.BACK),
    },
  ];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const button =
    variant === "button" ? (
      <Button
        variant="contained"
        startIcon={<Download />}
        endIcon={<ArrowDropDown />}
        onClick={handleToggle}
        ref={anchorRef}
      >
        Download
      </Button>
    ) : (
      <Fab color="primary" onClick={handleToggle} ref={anchorRef}>
        <Download />
      </Fab>
    );

  return (
    <>
      {button}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map(({ label, action }) => (
                    <MenuItem key={label} onClick={action}>
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
