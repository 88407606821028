import { Grid, Typography, useTheme } from "@mui/material";

export const Footer: React.FC = () => {
  const { spacing } = useTheme();

  return (
    <Grid item xs={12}>
      <Typography
        variant="subtitle1"
        color="#7e7e7e"
        align="center"
        fontSize="0.75rem"
        sx={{ paddingBottom: spacing(2) }}
      >
        © {new Date().getFullYear()} Steamforged Games Ltd. All Rights Reserved.
        <br />
        Registered in England with company number 09091884 and VAT registration
        GB189304189.
      </Typography>
    </Grid>
  );
};
