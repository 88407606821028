import { Grid, useTheme } from "@mui/material";
import { Banner } from "./Banner";
import { Footer } from "./Footer";
import { Header } from "./Header";

export const Frame: React.FC = ({ children }) => {
  const { spacing } = useTheme();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Header />
        <Banner />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: "url('/lowerBackground.jpg')",
          backgroundPosition: "center top",
          backgroundRepeat: "no-repeat",
          minHeight: "75vh",
          backgroundColor: "#111",
          paddingBottom: spacing(5),
        }}
      >
        {children}
      </Grid>
      <Footer />
    </Grid>
  );
};
