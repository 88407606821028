import { dataProducerRegistry } from "../components/registry/data";
import { ElementPosition, GameCard, TemplateElement } from "../models";
import { doesCardMeetConditions } from "./conditions";

export const calcPosition = (
  coordinate: "x" | "y",
  position: ElementPosition,
  displacedByRefs: string[] | null | undefined,
  card: GameCard
) => {
  if (!displacedByRefs || !card.template) return position[coordinate];

  const { elements } = card.template;
  let calculation = position[coordinate];

  for (const displacedByRef of displacedByRefs) {
    const element = elements.find(({ ref }) => ref === displacedByRef);
    if (elementShouldDisplaceOthers(element, card, coordinate))
      calculation =
        calculation + ` + ${element?.displacesByCoordinates?.[coordinate]}`;
  }

  return `calc(${calculation})`;
};

const elementShouldDisplaceOthers = (
  element: TemplateElement | undefined,
  card: GameCard,
  coordinate: "x" | "y"
) => {
  return (
    element?.renderConditions &&
    element?.displacesByCoordinates?.[coordinate] &&
    dataProducerRegistry[element.source](element, card) &&
    doesCardMeetConditions(card, element.renderConditions)
  );
};
