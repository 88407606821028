import { GameCardSlot, PropertyValue } from "../../models";
import { templateSlugs } from "./initialiseData";
import {
  defaultEnemyCardProperties,
  defaultEnemyCardElements,
} from "./initialiseEnemyTemplate";
import {
  defaultHeroCardProperties,
  defaultHeroCardElements,
} from "./initialiseHeroTemplate";

type CardDefaultPropertyRegistry = {
  [key in templateSlugs]: PropertyValue[];
};

type CardDefaultElementRegistry = {
  [key in templateSlugs]: GameCardSlot[];
};

export const cardDefaultPropertyRegistry: CardDefaultPropertyRegistry = {
  bardsungHero: defaultHeroCardProperties,
  bardsungEnemy: defaultEnemyCardProperties,
};

export const cardDefaultElementRegistry: CardDefaultElementRegistry = {
  bardsungHero: defaultHeroCardElements,
  bardsungEnemy: defaultEnemyCardElements,
};
