import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import {
  upsertCardSlotById,
  findSlotForCardAndElement,
} from "../../../services/card";
import { EditorTitle } from "../EditorTitle";
import { ElementEditorProps } from "./types";

export const Text: React.FC<ElementEditorProps> = ({ element, card }) => {
  const [value, setValue] = useState<string>(
    findSlotForCardAndElement(card, element.ref)?.value || ""
  );

  useEffect(() => {
    // TODO: don't update on every keypress, maybe wait between them?
    upsertCardSlotById(card, element.ref, value);
  }, [value]);

  const { label, description } = element.sourceData.USER_INPUT!;
  const maxLength = element.typeData.TEXT!.validation?.maxLength || 999;

  return (
    <Grid item xs={12}>
      <Grid container>
        <EditorTitle title={label} toolTipText={description} />
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={value}
            helperText={`${value.length}/${maxLength}`}
            inputProps={{
              maxLength: maxLength,
            }}
            onChange={(e) => setValue(e.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
