import { DataStore } from "aws-amplify";
import { GameCard } from "../models";

export const findSlotForCardAndElement = (card: GameCard, refToFind: string) =>
  card.slots.find(({ elementRef }) => elementRef === refToFind);

export const upsertCardSlotById = async (
  card: GameCard,
  elementRef: string,
  value: string
) => {
  if (!findSlotForCardAndElement(card, elementRef)) {
    // just add straight to array
    await DataStore.save(
      GameCard.copyOf(card, (updated) => {
        updated.slots = [
          ...updated.slots,
          {
            elementRef,
            value,
          },
        ];
      })
    );
  } else {
    // find current item in array (using ref) and update it
    const indexOfCurrent = card.slots.findIndex(
      ({ elementRef: elementRefForSlot }) => elementRefForSlot === elementRef
    );
    await DataStore.save(
      GameCard.copyOf(card, (updated) => {
        updated.slots[indexOfCurrent] = {
          elementRef,
          value,
        };
      })
    );
  }
};

export const findCardPropValByRef = (card: GameCard, refToFind: string) =>
  card.propertyValues.find(({ propertyRef }) => propertyRef === refToFind);

export const upsertCardPropValByRef = async (
  card: GameCard,
  propertyRef: string,
  value: string
) => {
  if (!findCardPropValByRef(card, propertyRef)) {
    // just add straight to array
    await DataStore.save(
      GameCard.copyOf(card, (updated) => {
        updated.propertyValues = [
          ...updated.propertyValues,
          {
            propertyRef,
            value,
          },
        ];
      })
    );
  } else {
    // find current item in array (using ref) and update it
    const indexOfCurrent = card.propertyValues.findIndex(
      ({ propertyRef: propertyRefForSlot }) =>
        propertyRefForSlot === propertyRef
    );
    await DataStore.save(
      GameCard.copyOf(card, (updated) => {
        updated.propertyValues[indexOfCurrent] = {
          propertyRef,
          value,
        };
      })
    );
  }
};
