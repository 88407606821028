import reactStringReplace from "react-string-replace";
import { iconRegex } from "../constants";
import { GameSystem } from "../models";

const defaultIconStyles: React.CSSProperties = {
  verticalAlign: "middle",
  padding: "0 5px",
};

export const interpolateIconsForSystem = (
  text: string,
  system: GameSystem,
  iconSize: number
) => {
  const { icons } = system;

  return reactStringReplace(text, iconRegex, (match) => {
    const icon = icons?.find((i) => i.shortCode === match);
    if (!icon) return <>{match}</>;

    const { iconPath, label } = icon;
    return (
      <img
        src={iconPath}
        alt={label}
        style={{ ...defaultIconStyles, height: `${iconSize}px` }}
      />
    );
  });
};
