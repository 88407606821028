import { ImageElement, TextElement } from "../preview/renderers";

export const elementRenderers = {
  IMAGE: ImageElement,
  TEXT: TextElement,
  MULTITEXT: TextElement,
  SELECT_TEXT: TextElement,
  SELECT_IMAGE: ImageElement,
  SWITCH: TextElement,
};
