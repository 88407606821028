import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

export type ErrorAlertProps = {
  error: Error | undefined;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
};

export const ErrorAlert: React.FC<ErrorAlertProps> = ({ error, setError }) => {
  const handleClose = () => {
    setError(undefined);
  };

  return (
    <Dialog
      open={!!error}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Uh oh....."}</DialogTitle>
      <DialogContent>
        <DialogContentText
          data-testid="test-alert-dialog-description"
          id="alert-dialog-description"
        >
          {error?.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
