import {
  derivedLookupDataProducer,
  derivedValueDataProducer,
  constantDataProducer,
  userInputDataProducer,
} from "../preview/renderers/producers";
import { ElementSourceDataProducerRegistry } from "../preview/renderers/producers/types";

export const dataProducerRegistry: ElementSourceDataProducerRegistry = {
  DERIVED_LOOKUP: derivedLookupDataProducer,
  DERIVED_VALUE: derivedValueDataProducer,
  CONSTANT: constantDataProducer,
  USER_INPUT: userInputDataProducer,
};
