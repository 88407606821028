import { useEffect, useState } from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import {
  findCardPropValByRef,
  upsertCardPropValByRef,
} from "../../../services/card";
import { PropertyEditorProps } from "./types";
import { EditorTitle } from "../EditorTitle";

export const SwatchEditor: React.FC<PropertyEditorProps> = ({
  property,
  card,
}) => {
  const [value, setValue] = useState<string>(
    findCardPropValByRef(card, property.ref)?.value || ""
  );

  useEffect(() => {
    if (value) {
      upsertCardPropValByRef(card, property.ref, value);
    }
  }, [value]);

  const { label, description, values } = property;

  return (
    <Grid item>
      <Grid container>
        <EditorTitle title={label} toolTipText={description} />
      </Grid>
      <Grid container spacing={0}>
        {values.map(
          ({ value: thisValue, label, editorOptions: { SWATCH_PICKER } }) => {
            const { iconSelected, iconUnselected } = SWATCH_PICKER!;

            return (
              <Grid item xs={3} sm={2} lg={1} key={`key-${thisValue}`}>
                <Tooltip title={label}>
                  <IconButton onClick={() => setValue(thisValue)}>
                    <img
                      src={thisValue === value ? iconSelected : iconUnselected}
                      style={{ width: "100%" }}
                      alt={label}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            );
          }
        )}
      </Grid>
    </Grid>
  );
};
