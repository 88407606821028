import React, { createContext, useEffect, useRef, useState } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { GameCard, GameCardTemplate, GameSystem } from "../models";
import { setupSubscriptionsForModel } from "./observeDataStore";
import { initialiseData } from "../services/initialiseData";

export type SessionContextData = {
  gameSystem?: GameSystem;
  setGameSystem: React.Dispatch<React.SetStateAction<GameSystem | undefined>>;
  template?: GameCardTemplate;
  setTemplate: React.Dispatch<
    React.SetStateAction<GameCardTemplate | undefined>
  >;
  card?: GameCard;
  setCard: React.Dispatch<React.SetStateAction<GameCard | undefined>>;
  loading: boolean;
  rendering: boolean;
  setRendering: React.Dispatch<React.SetStateAction<boolean>>;
  cardFrontDomRef?: React.RefObject<HTMLDivElement>;
  cardBackDomRef?: React.RefObject<HTMLDivElement>;
};

export const SessionContext = createContext<SessionContextData>({
  setGameSystem: () => {},
  setTemplate: () => {},
  setCard: () => {},
  loading: true,
  rendering: false,
  setRendering: () => {},
});

export const SessionContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [gameSystem, setGameSystem] = useState<GameSystem>();
  const [template, setTemplate] = useState<GameCardTemplate>();
  const [card, setCard] = useState<GameCard>();
  // used for observing model in DataStore and updating context accordingly
  const [currentCardId, setCurrentCardId] = useState<string>();
  const [cardSubscription, setCardSubscription] = useState<any>();
  const [rendering, setRendering] = useState(false);
  const cardFrontDomRef = useRef<HTMLDivElement>(null);
  const cardBackDomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setupContext = async () => {
      await initialiseData();

      const systems = await DataStore.query(GameSystem);
      if (systems.length === 1) {
        console.debug("Found only one system, preselecting.");
        console.debug(systems[0]);
        setGameSystem(systems[0]);
      }

      const templates = await DataStore.query(GameCardTemplate);
      if (templates.length === 1) {
        console.debug("Found only one template, preselecting.");
        console.debug(templates[0]);
        setTemplate(templates[0]);
      }

      setLoading(false);
    };

    setupContext();
  }, []);

  useEffect(() => {
    console.debug("Card changed, new value:");
    console.debug(card);

    setupSubscriptionsForModel(
      "GameCard",
      GameCard,
      card,
      setCard,
      currentCardId,
      setCurrentCardId,
      cardSubscription,
      setCardSubscription
    );
  }, [card]);

  const contextData: SessionContextData = {
    gameSystem,
    setGameSystem,
    template,
    setTemplate,
    card,
    setCard,
    cardFrontDomRef,
    cardBackDomRef,
    loading,
    rendering,
    setRendering,
  };

  return (
    <SessionContext.Provider value={contextData}>
      {children}
    </SessionContext.Provider>
  );
};
