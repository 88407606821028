import { calcPosition } from "../../../services/position";
import { localStorageFilePrefix } from "../../../services/storage";
import { dataProducerRegistry } from "../../registry/data";
import { ElementProps } from "./types";

export const ImageElement: React.FC<ElementProps> = ({ card, element }) => {
  let currentValue = dataProducerRegistry[element.source](element, card);

  if (!currentValue) return <></>;

  if (currentValue.startsWith(localStorageFilePrefix)) {
    currentValue = localStorage.getItem(currentValue) || currentValue;
  }

  const {
    position: { z },
  } = element!;

  const {
    size: { width, height },
  } = element.typeData.IMAGE!;

  const styles: React.CSSProperties = {
    position: "absolute",
    left: calcPosition("x", element.position, element.displacedByRefs, card),
    top: calcPosition("y", element.position, element.displacedByRefs, card),
    zIndex: z,
    width,
    height,
  };

  return <img src={currentValue} style={styles} alt={element.ref} />;
};
