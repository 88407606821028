import { Box } from "@mui/material";

export function Banner() {
  return (
    <Box
      sx={{
        height: "15vh",
        overflow: "hidden",
        backgroundImage: "url('/upperBackground.jpg')",
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="img"
        sx={{
          height: "80%",
        }}
        src="/logo473.png"
        alt="Bardsung Logo"
      />
    </Box>
  );
}
