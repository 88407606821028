import { useContext } from "react";
import { InputAdornment, IconButton } from "@mui/material";
import {
  SessionContextData,
  SessionContext,
} from "../../../../context/SessionContext";

type IconPickerButtonProps = {
  onClick: any;
};

export const IconPickerButton: React.FC<IconPickerButtonProps> = ({
  onClick,
}) => {
  const { template } = useContext<SessionContextData>(SessionContext);

  if (!template?.system?.icons?.length) return <></>;

  const defaultIcon = template.system.icons[0].iconPath;

  return (
    <InputAdornment position="start" sx={{ alignItems: "flex-end" }}>
      <IconButton onClick={onClick}>
        <img src={defaultIcon} width="32" alt="Insert game icons" />
      </IconButton>
    </InputAdornment>
  );
};
