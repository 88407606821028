import React, { useContext } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import {
  SessionContext,
  SessionContextData,
} from "../../context/SessionContext";
import ElementEditor from "./ElementEditor";
import { ElementSource, TemplateElement, CardSide } from "../../models";
import { PropertyEditor } from "./PropertyEditor";
import { doesCardMeetConditions } from "../../services/conditions";

type EditorProps = {
  selectedSide: CardSide;
};

const Editor: React.FC<EditorProps> = ({ selectedSide }) => {
  const { card, template } = useContext<SessionContextData>(SessionContext);

  if (!template) return <div>No template selected</div>;
  if (!card) return <div>No card selected</div>;

  const { elements, properties } = template;

  return (
    <Card
      elevation={6}
      sx={{
        mx: "auto",
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {properties.map((p) => (
                <PropertyEditor
                  key={`property-${p.ref}`}
                  property={p}
                  card={card}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} columnSpacing={{ xs: 4, md: 5 }}>
              {elements
                ?.filter((e): e is TemplateElement => !!e)
                ?.filter(({ source }) => source === ElementSource.USER_INPUT)
                ?.filter(
                  ({ position: { side } }) =>
                    side === selectedSide || side === CardSide.BOTH
                )
                .filter(
                  ({ renderConditions }) =>
                    !renderConditions?.length ||
                    doesCardMeetConditions(card, renderConditions)
                )
                .map((element) => (
                  <ElementEditor
                    key={`element-${element.type}-${element.ref}`}
                    element={element}
                    card={card}
                  />
                ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Editor;
