import { DataStore } from "aws-amplify";
import {
  ElementSource,
  GameCardTemplate,
  TemplateElement,
  TemplateProperty,
} from "../../models";
import { getSystemBySlug, getTemplateBySlug } from "./initialiseData";

const templateSlug = "bardsungEnemy";

export const initialiseEnemyTemplate = async () => {
  const bardsungSystem = await getSystemBySlug("bardsung");
  let bardsungEnemyTemplate: GameCardTemplate = await getTemplateBySlug(
    templateSlug
  );

  if (!bardsungEnemyTemplate) {
    console.debug("no existing Bardsung Enemy Card template found, creating");

    const properties: TemplateProperty[] = [
      {
        ref: "type",
        label: "Choose enemy type",
        values: [
          {
            value: "standard",
            label: "Standard",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/BS-Icons-Black-Blank.png`,
                iconSelected: `/icons/${templateSlug}/BS-Icons-Grey-Blank.png`,
              },
            },
          },
          {
            value: "hobgoblin",
            label: "Hobgoblin",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/BS-Icons-Black-Hobgoblin.png`,
                iconSelected: `/icons/${templateSlug}/BS-Icons-Grey-Hobgoblin.png`,
              },
            },
          },
          {
            value: "bugbear",
            label: "Bugbear",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/BS-Icons-Black-Bugbear.png`,
                iconSelected: `/icons/${templateSlug}/BS-Icons-Grey-Bugbear.png`,
              },
            },
          },
          {
            value: "duergar",
            label: "Duergar",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/BS-Icons-Black-Duergar.png`,
                iconSelected: `/icons/${templateSlug}/BS-Icons-Grey-Duergar.png`,
              },
            },
          },
          {
            value: "mechanica",
            label: "Mechanica",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/BS-Icons-Black-Mechanica.png`,
                iconSelected: `/icons/${templateSlug}/BS-Icons-Grey-Mechanica.png`,
              },
            },
          },
          {
            value: "spirit",
            label: "Spirit",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/BS-Icons-Black-Spirit.png`,
                iconSelected: `/icons/${templateSlug}/BS-Icons-Grey-Spirit.png`,
              },
            },
          },
          {
            value: "wandering",
            label: "Wandering Monster",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/BS-Icons-Black-WanderingMonster.png`,
                iconSelected: `/icons/${templateSlug}/BS-Icons-Grey-WanderingMonster.png`,
              },
            },
          },
          {
            value: "zombie",
            label: "Zombie",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/BS-Icons-Enemy-Undead.png`,
                iconSelected: `/icons/${templateSlug}/BS-Icons-Grey-Undead.png`,
              },
            },
          },
          {
            value: "infected",
            label: "Infected",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/BS-Icons-Black-Infected.png`,
                iconSelected: `/icons/${templateSlug}/BS-Icons-Grey-Infected.png`,
              },
            },
          },
          {
            value: "possessed",
            label: "Possessed",
            editorOptions: {
              SWATCH_PICKER: {
                iconUnselected: `/icons/${templateSlug}/BS-Icons-Black-Possessed.png`,
                iconSelected: `/icons/${templateSlug}/BS-Icons-Grey-Possessed.png`,
              },
            },
          },
        ],
        editorType: "SWATCH_PICKER",
      },
      {
        ref: "enemyHeatUp",
        label: "Does the enemy have `Heat Up`?",
        description: "Selecting `yes` will change the card back.",
        values: [
          {
            label: "Yes",
            value: "heatup",
            editorOptions: {},
          },
          {
            label: "No",
            value: "normal",
            editorOptions: {},
          },
        ],
        editorType: "RADIO",
      },
      {
        ref: "miniBoss",
        label: "Is the enemy a mini-boss?",
        description:
          "Mini-bosses are a type of advanced enemy, selecting `yes` will add an additional name field to the card",
        values: [
          {
            label: "Yes",
            value: "miniBoss",
            editorOptions: {},
          },
          {
            label: "No",
            value: "standard",
            editorOptions: {},
          },
        ],
        editorType: "RADIO",
      },
      {
        ref: "bannerHeight",
        label: "Single or double height banner?",
        values: [
          {
            label: "Single",
            value: "single",
            editorOptions: {},
          },
          {
            label: "Double",
            value: "double",
            editorOptions: {},
          },
        ],
        editorType: "RADIO",
      },
      {
        ref: "enemyImageStyle",
        label: "Does your enemy art have a transparent background?",
        description: "If you're not sure, try 'no' first!",
        values: [
          {
            label: "Yes, there's a transparent background",
            value: "transparent",
            editorOptions: {},
          },
          {
            label: "No, it's a solid image",
            value: "solid",
            editorOptions: {},
          },
        ],
        editorType: "RADIO",
      },
    ];

    const elements: TemplateElement[] = [
      {
        ref: "enemyFrontTransparentBackground",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "standard",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "hobgoblin",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "bugbear",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "duergar",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "mechanica",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "spirit",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "wandering",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "zombie",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Zombie-Profile-Card-BG.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "infected",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Infected-Profile-Card-BG.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "possessed",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Posessed-Profile-Card-BG.jpeg`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "0%",
          y: "0%",
          z: 0,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyImageStyle",
            value: "transparent",
          },
        ],
      },
      {
        ref: "enemyFrontSolidBackground",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "standard",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "hobgoblin",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "bugbear",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "duergar",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "mechanica",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "spirit",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "wandering",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "zombie",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Zombie-Profile-Card-BG-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "infected",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Infected-Profile-Card-BG-Frame.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "possessed",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Posessed-Profile-Card-BG-Frame.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "0%",
          y: "0%",
          z: 10,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyImageStyle",
            value: "solid",
          },
        ],
      },
      {
        ref: "enemyTNFront",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Elements-Enemy-Type-TN.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "23.81%",
              height: "16.85%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "3.60%",
          y: "3.5%",
          z: 25,
        },
      },
      {
        ref: "enemyTNBack",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Elements-Enemy-Type-TN.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "23.81%",
              height: "16.85%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "3.60%",
          y: "3.5%",
          z: 25,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "miniBossBannerFront",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "standard",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "hobgoblin",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "bugbear",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "duergar",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "mechanica",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "spirit",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "wandering",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "zombie",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Zombie.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "infected",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Infected.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "possessed",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Possessed.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "60.12%",
              height: "12.2%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "19.75%",
          y: "4.30%",
          z: 20,
        },
        displacesByCoordinates: {
          y: "5.2%",
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "miniBoss",
            value: "miniBoss",
          },
        ],
      },
      {
        ref: "enemyTopBannerSingleFront",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "standard",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "hobgoblin",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "bugbear",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "duergar",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "mechanica",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "spirit",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "wandering",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "zombie",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Zombie.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "infected",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Infected.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "possessed",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Possessed.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "77.12%",
              height: "14.48%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "11.39%",
          y: "5.98%",
          z: 20,
        },
        displacedByRefs: ["miniBossBannerFront"],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "bannerHeight",
            value: "single",
          },
        ],
      },
      {
        ref: "enemyTopBannerDoubleFront",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "standard",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Double-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "hobgoblin",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Double-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "bugbear",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Double-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "duergar",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Double-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "mechanica",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Double-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "spirit",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Double-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "wandering",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Double-Standard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "zombie",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Double-Zombie.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "infected",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Double-Infected.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "possessed",
                  },
                ],
                value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Double-Possessed.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "77.12%",
              height: "18.0%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "11.39%",
          y: "6.98%",
          z: 20,
        },
        displacedByRefs: ["miniBossBannerFront"],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "bannerHeight",
            value: "double",
          },
        ],
      },
      {
        ref: "miniBossBannerBack",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Flipped.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "60.12%",
              height: "12.2%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "19.75%",
          y: "4.30%",
          z: 20,
        },
        displacesByCoordinates: {
          y: "5.2%",
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "miniBoss",
            value: "miniBoss",
          },
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyTopBannerBackSingle",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Single-Flipped.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "77.12%",
              height: "14.48%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "11.39%",
          y: "5.98%",
          z: 20,
        },
        displacedByRefs: ["miniBossBannerBack"],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "PROPERTY",
            ref: "bannerHeight",
            value: "single",
          },
        ],
      },
      {
        ref: "enemyTopBannerBackDouble",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/banners/${templateSlug}/BS-Enemy-Profile-Card-Banner-Double-Flipped.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "77.12%",
              height: "18.0%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "11.39%",
          y: "6.98%",
          z: 20,
        },
        displacedByRefs: ["miniBossBannerBack"],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "PROPERTY",
            ref: "bannerHeight",
            value: "double",
          },
        ],
      },
      {
        ref: "enemyTypeIconFront",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "standard",
                  },
                ],
                value: ``,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "hobgoblin",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Hobgoblin.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "bugbear",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Bugbear.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "duergar",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Duergar.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "mechanica",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Mechanica.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "spirit",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Spirit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "wandering",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-WanderingMonster.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "zombie",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Enemy-Undead.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "infected",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Infected.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "possessed",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Possessed.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "81%",
          y: "77.5%",
          z: 30,
        },
      },
      {
        ref: "enemyTypeIconBack",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "standard",
                  },
                ],
                value: ``,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "hobgoblin",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Hobgoblin.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "bugbear",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Bugbear.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "duergar",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Duergar.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "mechanica",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Mechanica.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "spirit",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Spirit.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "wandering",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-WanderingMonster.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "zombie",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Enemy-Undead.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "infected",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Infected.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "possessed",
                  },
                ],
                value: `/icons/${templateSlug}/BS-Icons-Black-Possessed.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "81%",
          y: "77.5%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyHeatUpIconFront",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Icons-Black-FlipCard.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "81%",
          y: "86.9%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyHeatUpIconBack",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Icons-Black-FlippedCard.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "81%",
          y: "86.9%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemySpeedFrameFront",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Elements-Enemy-Slot-Speed.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "6.83%",
          y: "20.39%",
          z: 20,
        },
      },
      {
        ref: "enemySpeedFrameBack",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Elements-Enemy-Slot-Speed.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "6.83%",
          y: "20.39%",
          z: 20,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyToughnessFrameFront",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Elements-Enemy-Slot-Threat.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "6.83%",
          y: "29.34%",
          z: 20,
        },
      },
      {
        ref: "enemyToughnessFrameBack",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Elements-Enemy-Slot-Threat.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "6.83%",
          y: "29.34%",
          z: 20,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyGritFrameFront",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Elements-Enemy-Slot-Health.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "77.71%",
          y: "20.39%",
          z: 20,
        },
      },
      {
        ref: "enemyGritFrameBack",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Elements-Enemy-Slot-Health.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "77.71%",
          y: "20.39%",
          z: 20,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyGritValueFront",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "enemyHeatUp",
                    value: "heatup",
                  },
                ],
                value: `/icons/${templateSlug}/grit/BS-Icons-Enemy-Type-FlipCard.png`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "enemyHeatUp",
                    value: "normal",
                  },
                ],
                value: `/icons/${templateSlug}/grit/BS-Icons-Enemy-Type-Kill.png`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "6.96%",
              height: "5%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "78.5%",
          y: "22.39%",
          z: 30,
        },
      },
      {
        ref: "enemyGritValueBack",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/icons/${templateSlug}/grit/BS-Icons-Enemy-Type-Kill.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "6.96%",
              height: "5%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "78.5%",
          y: "22.39%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyGoldValueFrameFront",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Elements-Enemy-Slot-Treasure.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "77.71%",
          y: "29.35%",
          z: 20,
        },
      },
      {
        ref: "enemyGoldValueFrameBack",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Elements-Enemy-Slot-Treasure.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "17.04%",
              height: "8.96%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "77.71%",
          y: "29.35%",
          z: 20,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemySpecialRulesFrameFront",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Enemy-Element-SpecialRule-1B.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "82.07%",
              height: "16.94%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "8.99%",
          y: "77.69%",
          z: 25,
        },
      },
      {
        ref: "enemySpecialRulesFrameBack",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Enemy-Element-SpecialRule-1.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "82.07%",
              height: "16.94%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "8.99%",
          y: "77.69%",
          z: 25,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyBackgroundBackHeatUpTransparent",
        source: "DERIVED_LOOKUP",
        sourceData: {
          DERIVED_LOOKUP: {
            values: [
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "standard",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG2.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "hobgoblin",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG2.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "bugbear",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG2.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "duergar",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG2.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "mechanica",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG2.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "spirit",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG2.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "wandering",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Standard-Profile-Card-BG2.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "zombie",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Zombie-Profile-Card-BG2.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "infected",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Infected-Profile-Card-BG2.jpeg`,
              },
              {
                conditions: [
                  {
                    type: "PROPERTY",
                    ref: "type",
                    value: "possessed",
                  },
                ],
                value: `/backgrounds/${templateSlug}/BS-Enemy-Posessed-Profile-Card-BG2.jpeg`,
              },
            ],
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "0%",
          y: "0%",
          z: 0,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "PROPERTY",
            ref: "enemyImageStyle",
            value: "transparent",
          },
        ],
      },
      {
        ref: "enemyBackgroundBackHeatUpTransparent",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/backgrounds/${templateSlug}/BS-Enemy-Heatup-Profile-Card-BG-Frame.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "0%",
          y: "0%",
          z: 10,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "PROPERTY",
            ref: "enemyImageStyle",
            value: "solid",
          },
        ],
      },
      {
        ref: "enemyBackgroundBackNormal",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/backgrounds/${templateSlug}/BS-Cards-BG-EnemyBigger-Back-DN.jpeg`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "0%",
          y: "0",
          z: 0,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "normal",
          },
        ],
      },
      {
        ref: "MiniBossNameFrontSingle",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Name your mini-boss",
          },
        },
        type: "TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 20,
            padding: 4,
            lineHeight: 1,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxLength: 15,
            },
            textShrinkSettings: {
              minFontSize: 15,
              width: "40.46%",
              height: "5%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "28.44%",
          y: "6.25%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "miniBoss",
            value: "miniBoss",
          },
        ],
      },
      {
        ref: "EnemyNameFrontSingle",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Name your enemy",
          },
        },
        type: "TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 28,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxLength: 15,
            },
            textShrinkSettings: {
              minFontSize: 25,
              width: "51.46%",
              height: "6%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "23.44%",
          y: "8.25%",
          z: 30,
        },
        displacedByRefs: ["miniBossBannerFront"],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "bannerHeight",
            value: "single",
          },
        ],
      },
      {
        ref: "EnemyNameFrontDouble",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Name your enemy",
          },
        },
        type: "MULTITEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 28,
            align: "center",
            lineHeight: 0.9,
            textWrapStyle: "BREAK_SPACES",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxLength: 28,
            },
            textShrinkSettings: {
              minFontSize: 19,
              width: "51.46%",
              height: "8.95%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "23.44%",
          y: "7.95%",
          z: 30,
        },
        displacedByRefs: ["miniBossBannerFront"],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "bannerHeight",
            value: "double",
          },
        ],
      },
      {
        ref: "enemyImageFrontTransparent",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Enemy Art",
            description:
              "Upload your own enemy art (411x562 pixels). Transparent PNGs work best.",
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "0%",
          y: "12.44%",
          z: 5,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyImageStyle",
            value: "transparent",
          },
        ],
      },
      {
        ref: "enemyImageFrontSolid",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Enemy Art",
            description:
              "Upload your own enemy art (411x562 pixels). Transparent PNGs work best.",
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "0%",
          y: "0%",
          z: 5,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyImageStyle",
            value: "solid",
          },
        ],
      },
      {
        ref: "MiniBossNameBackSingle",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Name your mini-boss",
          },
        },
        type: "TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 20,
            padding: 4,
            lineHeight: 1,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxLength: 15,
            },
            textShrinkSettings: {
              minFontSize: 15,
              width: "40.46%",
              height: "5%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "28.44%",
          y: "6.25%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "miniBoss",
            value: "miniBoss",
          },
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "EnemyNameBackSingle",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Name your enemy",
          },
        },
        type: "TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 28,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxLength: 15,
            },
            textShrinkSettings: {
              minFontSize: 25,
              width: "51.46%",
              height: "6%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "23.44%",
          y: "8.25%",
          z: 30,
        },
        displacedByRefs: ["miniBossBannerBack"],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "bannerHeight",
            value: "single",
          },
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "EnemyNameBackDouble",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Name your enemy",
          },
        },
        type: "MULTITEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 28,
            align: "center",
            lineHeight: 0.9,
            textWrapStyle: "BREAK_SPACES",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxLength: 28,
            },
            textShrinkSettings: {
              minFontSize: 19,
              width: "51.46%",
              height: "8.95%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "23.44%",
          y: "7.95%",
          z: 30,
        },
        displacedByRefs: ["miniBossBannerBack"],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "bannerHeight",
            value: "double",
          },
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyImageBackTransparent",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Enemy Art",
            description:
              "Upload your own enemy art (411x562 pixels). Transparent PNGs work best.",
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "0%",
          y: "12.44%",
          z: 5,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "PROPERTY",
            ref: "enemyImageStyle",
            value: "transparent",
          },
        ],
      },
      {
        ref: "enemyImageBackSolid",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Enemy Art",
            description:
              "Upload your own enemy art (411x562 pixels). Transparent PNGs work best.",
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "100%",
              height: "100%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "0%",
          y: "0%",
          z: 5,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "PROPERTY",
            ref: "enemyImageStyle",
            value: "solid",
          },
        ],
      },
      {
        ref: "enemyTargetNumberFront",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Target Number (TN)",
            description:
              "Whenever this enemy is attacked or makes an attack, the hero uses this target number.",
            values: [
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
              {
                label: "10",
                value: "10",
              },
              {
                label: "11",
                value: "11",
              },
              {
                label: "12",
                value: "12",
              },
              {
                label: "13",
                value: "13",
              },
              {
                label: "14",
                value: "14",
              },
              {
                label: "15",
                value: "15",
              },
              {
                label: "16",
                value: "16",
              },
              {
                label: "17",
                value: "17",
              },
              {
                label: "18",
                value: "18",
              },
              {
                label: "19",
                value: "19",
              },
              {
                label: "20",
                value: "20",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 50,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
            textShrinkSettings: {
              height: "20%",
              width: "23%",
              minFontSize: 50,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "3.9%",
          y: "6.5%",
          z: 30,
        },
      },
      {
        ref: "enemyTargetNumberModifierFront",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "TN Modifier",
            description:
              "Enemies can be resilient or vulnerable to certain attack properties.",
            values: [
              {
                label: "-",
                value: ``,
              },
              {
                label: "Magical Advantage",
                value: `/icons/${templateSlug}/modifiers/BS-Icons-Black-EnemyMagicalAdvantage.png`,
              },
              {
                label: "Magical Disadvantage",
                value: `/icons/${templateSlug}/modifiers/BS-Icons-Black-EnemyMagicalDisadvantage.png`,
              },
              {
                label: "Physical Advantage",
                value: `/icons/${templateSlug}/modifiers/BS-Icons-Black-EnemyPhysicalAdvantage.png`,
              },
              {
                label: "Physical Disadvantage",
                value: `/icons/${templateSlug}/modifiers/BS-Icons-Black-EnemyPhysicalDisadvantage.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "9.08%",
              height: "6.57%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "19.40%",
          y: "13.90%",
          z: 26,
        },
      },
      {
        ref: "enemyTargetNumberBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Target Number (TN)",
            description:
              "Whenever this enemy is attacked or makes an attack, the hero uses this target number.",
            values: [
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
              {
                label: "10",
                value: "10",
              },
              {
                label: "11",
                value: "11",
              },
              {
                label: "12",
                value: "12",
              },
              {
                label: "13",
                value: "13",
              },
              {
                label: "14",
                value: "14",
              },
              {
                label: "15",
                value: "15",
              },
              {
                label: "16",
                value: "16",
              },
              {
                label: "17",
                value: "17",
              },
              {
                label: "18",
                value: "18",
              },
              {
                label: "19",
                value: "19",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 50,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
            textShrinkSettings: {
              height: "20%",
              width: "23%",
              minFontSize: 50,
            },
          },
        },
        position: {
          side: "BACK",
          x: "3.9%",
          y: "6.5%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyTargetNumberModifierBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "TN Modifier",
            description:
              "Enemies can be resilient or vulnerable to certain attack properties.",
            values: [
              {
                label: "-",
                value: ``,
              },
              {
                label: "Magical Advantage",
                value: `/icons/${templateSlug}/modifiers/BS-Icons-Black-EnemyMagicalAdvantage.png`,
              },
              {
                label: "Magical Disadvantage",
                value: `/icons/${templateSlug}/modifiers/BS-Icons-Black-EnemyMagicalDisadvantage.png`,
              },
              {
                label: "Physical Advantage",
                value: `/icons/${templateSlug}/modifiers/BS-Icons-Black-EnemyPhysicalAdvantage.png`,
              },
              {
                label: "Physical Disadvantage",
                value: `/icons/${templateSlug}/modifiers/BS-Icons-Black-EnemyPhysicalDisadvantage.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "9.08%",
              height: "6.57%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "19.40%",
          y: "13.90%",
          z: 26,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemySpeedFront",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Speed",
            description: "How many zones your enemy can move.",
            values: [
              {
                label: "-",
                value: "-",
              },
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "17%",
          y: "21.33%",
          z: 30,
        },
      },
      {
        ref: "enemySpeedBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Speed",
            description: "How many zones your enemy can move.",
            values: [
              {
                label: "-",
                value: "-",
              },
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "BACK",
          x: "17%",
          y: "21.33%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyDefenseFront",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Toughness",
            description:
              "The number a damage roll must equal or exceed to inflict a critical hit against this enemy.",
            values: [
              {
                label: "-",
                value: "-",
              },
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "17%",
          y: "30.58%",
          z: 30,
        },
      },
      {
        ref: "enemyDefenseBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Toughness",
            description:
              "The number a damage roll must equal or exceed to inflict a critical hit against this enemy.",
            values: [
              {
                label: "-",
                value: "-",
              },
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "BACK",
          x: "17%",
          y: "30.58%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyGoldValueFront",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Gold Value",
            description:
              "How many gold tokens the heroes earn if they remove this card from the marching order.",
            values: [
              {
                label: "-",
                value: "-",
              },
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "80.67%",
          y: "30.18%",
          z: 30,
        },
      },
      {
        ref: "enemyGoldValueBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Gold Value",
            description:
              "How many gold tokens the heroes earn if they remove this card from the marching order.",
            values: [
              {
                label: "-",
                value: "-",
              },
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "BACK",
          x: "80.67%",
          y: "30.18%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1FrameFront",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Enemy-Equipment-Upgrade-Slots.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "53.63%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "28.70%",
          y: "69.20%",
          z: 25,
        },
        displacedByRefs: [
          "enemyAttackProfile2Front",
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1FrameBack",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Enemy-Equipment-Upgrade-Slots.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "53.63%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "28.70%",
          y: "69.20%",
          z: 25,
        },
        displacedByRefs: [
          "enemyAttackProfile2Back",
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
        ],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1RangeFront",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Icons-Grey-Range.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "18.80%",
          y: "69.20%",
          z: 27,
        },
        displacedByRefs: [
          "enemyAttackProfile2Type",
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1RangeBack",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Icons-Grey-Range.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "18.80%",
          y: "69.20%",
          z: 27,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
          "enemyAttackProfile2TypeBack",
        ],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1ToggleFront",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "First Attack Profile",
            description: "The enemy’s attack profile.",
          },
        },
        type: "SWITCH",
        typeData: {
          TEXT: {
            color: "black",
            font: "Mairon",
            fontSize: 0,
            align: "center",
            textDrawStyle: "NORMAL",
          },
        },
        position: {
          side: "FRONT",
          x: "0%",
          y: "0%",
          z: 0,
        },
      },
      {
        ref: "enemyAttackProfile1ToggleBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "First Attack Profile",
            description: "The enemy’s attack profile.",
          },
        },
        type: "SWITCH",
        typeData: {
          TEXT: {
            color: "black",
            font: "Mairon",
            fontSize: 0,
            align: "center",
            textDrawStyle: "NORMAL",
          },
        },
        position: {
          side: "BACK",
          x: "0%",
          y: "0%",
          z: 0,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1Type",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Hit/Miss",
            values: [
              {
                label: "Hit",
                value: "hit",
              },
              {
                label: "Miss",
                value: "miss",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "black",
            font: "Mairon",
            fontSize: 25,
            align: "center",
            textDrawStyle: "WITH_STROKE",

            textShrinkSettings: {
              height: "8.57%",
              width: "20.17%",
              minFontSize: 14,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "31.20%",
          y: "71.0%",
          z: 25,
        },
        displacedByRefs: [
          "enemyAttackProfile2Front",
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1RangeValueFront",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Range",
            description: "The enemy’s attack range.",
            values: [
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "23.20%",
          y: "69.80%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfile2Type",
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1TypeBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Hit/Miss",
            values: [
              {
                label: "Hit",
                value: "hit",
              },
              {
                label: "Miss",
                value: "miss",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "black",
            font: "Mairon",
            fontSize: 25,
            align: "center",
            textDrawStyle: "WITH_STROKE",

            textShrinkSettings: {
              height: "8.57%",
              width: "20.17%",
              minFontSize: 14,
            },
          },
        },
        position: {
          side: "BACK",
          x: "31.20%",
          y: "71.0%",
          z: 25,
        },
        displacedByRefs: [
          "enemyAttackProfile2Back",
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
        ],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1RangeValueBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Range",
            description: "The enemy’s attack range.",
            values: [
              {
                label: "0",
                value: "0",
              },
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 30,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxValue: 9,
            },
          },
        },
        position: {
          side: "BACK",
          x: "23.20%",
          y: "69.80%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
          "enemyAttackProfile2TypeBack",
        ],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfilePropertyFront",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Property",
            description:
              "The behaviour card the enemy follows during its turn.",
            values: [
              {
                label: "-",
                value: ``,
              },
              {
                label: "Physical",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Physical.png`,
              },
              {
                label: "Magical",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Magical.png`,
              },
              {
                label: "Powerful",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Powerful.png`,
              },
              {
                label: "Area of Effect",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-AOE.png`,
              },
              {
                label: "Pulse",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Pulse.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "13.40%",
          y: "69.20%",
          z: 26,
        },
        displacedByRefs: [
          "enemyAttackProfile2Type",
          "enemyAttackProfileProperty1Front",
        ],
        displacesByCoordinates: {
          x: "5%",
        },
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfileProperty1Front",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Property",
            description:
              "The behaviour card the enemy follows during its turn.",
            values: [
              {
                label: "-",
                value: ``,
              },
              {
                label: "Physical",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Physical.png`,
              },
              {
                label: "Magical",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Magical.png`,
              },
              {
                label: "Powerful",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Powerful.png`,
              },
              {
                label: "Area of Effect",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-AOE.png`,
              },
              {
                label: "Pulse",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Pulse.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "8.40%",
          y: "69.20%",
          z: 27,
        },
        displacedByRefs: ["enemyAttackProfile2Type"],
        displacesByCoordinates: {
          x: "5%",
        },
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfilePropertyBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Property",
            description:
              "The behaviour card the enemy follows during its turn.",
            values: [
              {
                label: "-",
                value: ``,
              },
              {
                label: "Physical",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Physical.png`,
              },
              {
                label: "Magical",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Magical.png`,
              },
              {
                label: "Powerful",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Powerful.png`,
              },
              {
                label: "Area of Effect",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-AOE.png`,
              },
              {
                label: "Pulse",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Pulse.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "13.40%",
          y: "69.20%",
          z: 25,
        },
        displacedByRefs: [
          "enemyAttackProfileProperty1Back",
          "enemyAttackProfile2TypeBack",
        ],
        displacesByCoordinates: {
          x: "5%",
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfileProperty1Back",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Property",
            description:
              "The behaviour card the enemy follows during its turn.",
            values: [
              {
                label: "-",
                value: ``,
              },
              {
                label: "Physical",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Physical.png`,
              },
              {
                label: "Magical",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Magical.png`,
              },
              {
                label: "Powerful",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Powerful.png`,
              },
              {
                label: "Area of Effect",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-AOE.png`,
              },
              {
                label: "Pulse",
                value: `/icons/${templateSlug}/attackProperties/BS-Icons-Grey-Pulse.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "8.40%",
          y: "69.20%",
          z: 27,
        },
        displacedByRefs: ["enemyAttackProfile2TypeBack"],
        displacesByCoordinates: {
          x: "5%",
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1Effects1Front",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "54.2%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfile2Front",
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1Effects1Back",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "54.2%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfile2Back",
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
        ],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1Effects2Front",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "63.2%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfile2Front",
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1Effects2Back",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "63.2%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfile2Back",
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
        ],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1Effects3Front",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "72.1%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfile2Front",
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile1Effects3Back",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "72.1%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfile2Back",
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
        ],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile1ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile2ToggleFront",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Second Attack Profile",
            description: "The enemy’s attack profile.",
          },
        },
        type: "SWITCH",
        typeData: {
          TEXT: {
            color: "black",
            font: "Mairon",
            fontSize: 0,
            align: "center",
            textDrawStyle: "NORMAL",
          },
        },
        position: {
          side: "FRONT",
          x: "0%",
          y: "0%",
          z: 0,
        },
      },
      {
        ref: "enemyAttackProfile2ToggleBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Second Attack Profile",
            description: "The enemy’s attack profile.",
          },
        },
        type: "SWITCH",
        typeData: {
          TEXT: {
            color: "black",
            font: "Mairon",
            fontSize: 0,
            align: "center",
            textDrawStyle: "NORMAL",
          },
        },
        position: {
          side: "BACK",
          x: "0%",
          y: "0%",
          z: 0,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyAttackProfile2Front",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Enemy-Equipment-Upgrade-Slots.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "53.63%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "28.70%",
          y: "69.20%",
          z: 25,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        displacesByCoordinates: {
          y: "-8.5%",
        },
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile2ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile2Back",
        source: "CONSTANT",
        sourceData: {
          CONSTANT: {
            value: `/frames/${templateSlug}/common/BS-Cards-Enemy-Equipment-Upgrade-Slots.png`,
          },
        },
        type: "IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "53.63%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "28.70%",
          y: "69.20%",
          z: 25,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
        ],
        displacesByCoordinates: {
          y: "-8.5%",
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile2ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile2Type",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Hit/Miss",
            values: [
              {
                label: "Hit",
                value: "hit",
              },
              {
                label: "Miss",
                value: "miss",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "black",
            font: "Mairon",
            fontSize: 25,
            align: "center",
            textDrawStyle: "WITH_STROKE",

            textShrinkSettings: {
              height: "8.57%",
              width: "20.17%",
              minFontSize: 14,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "31.20%",
          y: "71.0%",
          z: 25,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        displacesByCoordinates: {
          y: "-5%",
        },
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile2ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile2TypeBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Hit/Miss",
            values: [
              {
                label: "Hit",
                value: "hit",
              },
              {
                label: "Miss",
                value: "miss",
              },
            ],
          },
        },
        type: "SELECT_TEXT",
        typeData: {
          TEXT: {
            color: "black",
            font: "Mairon",
            fontSize: 25,
            align: "center",
            textDrawStyle: "WITH_STROKE",

            textShrinkSettings: {
              height: "8.57%",
              width: "20.17%",
              minFontSize: 14,
            },
          },
        },
        position: {
          side: "BACK",
          x: "31.20%",
          y: "71.0%",
          z: 25,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
        ],
        displacesByCoordinates: {
          y: "-5%",
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile2ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile2Effects1Front",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "54.2%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile2ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile2Effects1Back",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "54.2%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
        ],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile2ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile2Effects2Front",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "63.2%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile2ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile2Effects2Back",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "63.2%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
        ],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile2ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile2Effects3Front",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "72.1%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyFront",
          "enemyAttackProfileProperty1Front",
        ],
        renderConditions: [
          {
            type: "SLOT",
            ref: "enemyAttackProfile2ToggleFront",
            value: "true",
          },
        ],
      },
      {
        ref: "enemyAttackProfile2Effects3Back",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Attack Effects",
            description: "The attack’s effects if it hits.",
            values: [
              {
                label: "-",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
              },
              {
                label: "Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-LightWound.png`,
              },
              {
                label: "Serious Wound",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-SeriousWound.png`,
              },
              {
                label: "Push",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Push.png`,
              },
              {
                label: "Dodge",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Dodge.png`,
              },
              {
                label: "Blessing",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blessing.png`,
              },
              {
                label: "Inspire",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Inspire.png`,
              },
              {
                label: "Quicken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Quicken.png`,
              },
              {
                label: "Haste",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Haste.png`,
              },
              {
                label: "Slow",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Slow.png`,
              },
              {
                label: "Stop",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stop.png`,
              },
              {
                label: "Bane",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bane.png`,
              },
              {
                label: "Bleed",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Bleed.png`,
              },
              {
                label: "Burn",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Burn.png`,
              },
              {
                label: "Fatigue",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Fatigue.png`,
              },
              {
                label: "Frostbite",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Frostbite.png`,
              },
              {
                label: "Poison",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Poison.png`,
              },
              {
                label: "Stunned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Stunned.png`,
              },
              {
                label: "Sundered",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Sundered.png`,
              },
              {
                label: "Weaken",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Weaken.png`,
              },
              {
                label: "Pinned",
                value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Pinned.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "8.3%",
              height: "6.3%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "72.1%",
          y: "70.3%",
          z: 30,
        },
        displacedByRefs: [
          "enemyAttackProfilePropertyBack",
          "enemyAttackProfileProperty1Back",
        ],
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
          {
            type: "SLOT",
            ref: "enemyAttackProfile2ToggleBack",
            value: "true",
          },
        ],
      },
      {
        ref: "EnemySpecialRulesTitle",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Special Rule Name",
          },
        },
        type: "TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 18,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxLength: 20,
            },
            textShrinkSettings: {
              height: "4%",
              width: "79.17%",
              minFontSize: 14,
            },
          },
        },
        position: {
          side: "FRONT",
          x: "10.44%",
          y: "80.18%",
          z: 30,
        },
      },
      {
        ref: "EnemySpecialRulesTitleBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Special Rule Name",
          },
        },
        type: "TEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 18,
            align: "center",
            textDrawStyle: "WITH_STROKE",
            validation: {
              maxLength: 20,
            },
            textShrinkSettings: {
              height: "4%",
              width: "79.17%",
              minFontSize: 14,
            },
          },
        },
        position: {
          side: "BACK",
          x: "10.44%",
          y: "80.18%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "EnemySpecialRules",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Special Rule Details",
            description:
              "Type in your special rules here and insert icon with the selector.",
          },
        },
        type: "MULTITEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 15,
            align: "center",
            textWrapStyle: "BREAK_SPACES",
            textDrawStyle: "NORMAL",
            validation: {
              maxLength: 100,
            },
            textShrinkSettings: {
              width: "63%",
              height: "9.42%",
              minFontSize: 10,
            },
            useIcons: true,
          },
        },
        position: {
          side: "FRONT",
          x: "19.5%",
          y: "83.74%",
          z: 30,
        },
      },
      {
        ref: "EnemySpecialRulesBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Special Rule Details",
            description:
              "Type in your special rules here and insert icon with the selector.",
          },
        },
        type: "MULTITEXT",
        typeData: {
          TEXT: {
            color: "white",
            font: "Mairon",
            fontSize: 15,
            align: "center",
            textWrapStyle: "BREAK_SPACES",
            textDrawStyle: "NORMAL",
            validation: {
              maxLength: 100,
            },
            textShrinkSettings: {
              width: "63%",
              height: "9.42%",
              minFontSize: 10,
            },
            useIcons: true,
          },
        },
        position: {
          side: "BACK",
          x: "19.5%",
          y: "83.74%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyBehaviourTraitFront",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Behaviour Trait 1",
            description:
              "The behaviour card the enemy follows during its turn.",
            values: [
              {
                label: "-",
                value: ``,
              },
              {
                label: "Berserk",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Berserk.png`,
              },
              {
                label: "Draw Card",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-CardDraw.png`,
              },
              {
                label: "Cautious",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Cautious.png`,
              },
              {
                label: "Devour",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Devour.png`,
              },
              {
                label: "Disciplined",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Disciplined.png`,
              },
              {
                label: "Eliminate",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Eliminate.png`,
              },
              {
                label: "Ferocious",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Ferocious.png`,
              },
              {
                label: "Opportunistic",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Opportunistic.png`,
              },
              {
                label: "Reform",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Reform.png`,
              },
              {
                label: "Reposition",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Reposition.png`,
              },
              {
                label: "Retreat",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Retreat.png`,
              },
              {
                label: "TimeStopper",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-TimeStopper.png`,
              },
              {
                label: "Unrelenting",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Unrelenting.png`,
              },
              {
                label: "Vengeful",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Vengeful.png`,
              },
            ],
          },
        },

        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "6.5%",
          y: "77.5%",
          z: 30,
        },
      },
      {
        ref: "enemyBehaviourTraitBack",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Behaviour Trait 1",
            description:
              "The behaviour card the enemy follows during its turn.",
            values: [
              {
                label: "-",
                value: ``,
              },
              {
                label: "Berserk",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Berserk.png`,
              },
              {
                label: "Draw Card",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-CardDraw.png`,
              },
              {
                label: "Cautious",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Cautious.png`,
              },
              {
                label: "Devour",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Devour.png`,
              },
              {
                label: "Disciplined",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Disciplined.png`,
              },
              {
                label: "Eliminate",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Eliminate.png`,
              },
              {
                label: "Ferocious",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Ferocious.png`,
              },
              {
                label: "Opportunistic",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Opportunistic.png`,
              },
              {
                label: "Reform",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Reform.png`,
              },
              {
                label: "Reposition",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Reposition.png`,
              },
              {
                label: "Retreat",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Retreat.png`,
              },
              {
                label: "TimeStopper",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-TimeStopper.png`,
              },
              {
                label: "Unrelenting",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Unrelenting.png`,
              },
              {
                label: "Vengeful",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Vengeful.png`,
              },
            ],
          },
        },
        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "6.5%",
          y: "77.5%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
      {
        ref: "enemyBehaviourTraitFront2",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Behaviour Trait 2",
            description:
              "The behaviour card the enemy follows during its turn.",
            values: [
              {
                label: "-",
                value: ``,
              },
              {
                label: "Berserk",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Berserk.png`,
              },
              {
                label: "Draw Card",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-CardDraw.png`,
              },
              {
                label: "Cautious",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Cautious.png`,
              },
              {
                label: "Devour",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Devour.png`,
              },
              {
                label: "Disciplined",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Disciplined.png`,
              },
              {
                label: "Eliminate",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Eliminate.png`,
              },
              {
                label: "Ferocious",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Ferocious.png`,
              },
              {
                label: "Opportunistic",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Opportunistic.png`,
              },
              {
                label: "Reform",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Reform.png`,
              },
              {
                label: "Reposition",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Reposition.png`,
              },
              {
                label: "Retreat",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Retreat.png`,
              },
              {
                label: "TimeStopper",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-TimeStopper.png`,
              },
              {
                label: "Unrelenting",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Unrelenting.png`,
              },
              {
                label: "Vengeful",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Vengeful.png`,
              },
            ],
          },
        },

        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "FRONT",
          x: "6.5%",
          y: "86.9%",
          z: 30,
        },
      },
      {
        ref: "enemyBehaviourTraitBack2",
        source: ElementSource.USER_INPUT,
        sourceData: {
          USER_INPUT: {
            label: "Behaviour Trait 2",
            description:
              "The behaviour card the enemy follows during its turn.",
            values: [
              {
                label: "-",
                value: ``,
              },
              {
                label: "Berserk",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Berserk.png`,
              },
              {
                label: "Draw Card",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-CardDraw.png`,
              },
              {
                label: "Cautious",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Cautious.png`,
              },
              {
                label: "Devour",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Devour.png`,
              },
              {
                label: "Disciplined",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Disciplined.png`,
              },
              {
                label: "Eliminate",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Eliminate.png`,
              },
              {
                label: "Ferocious",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Ferocious.png`,
              },
              {
                label: "Opportunistic",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Opportunistic.png`,
              },
              {
                label: "Reform",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Reform.png`,
              },
              {
                label: "Reposition",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Reposition.png`,
              },
              {
                label: "Retreat",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Retreat.png`,
              },
              {
                label: "TimeStopper",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-TimeStopper.png`,
              },
              {
                label: "Unrelenting",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Unrelenting.png`,
              },
              {
                label: "Vengeful",
                value: `/icons/${templateSlug}/behaviours/BS-Icons-Enemy-Behaviour-Vengeful.png`,
              },
            ],
          },
        },

        type: "SELECT_IMAGE",
        typeData: {
          IMAGE: {
            size: {
              width: "12.08%",
              height: "8.57%",
            },
          },
        },
        position: {
          side: "BACK",
          x: "6.5%",
          y: "86.9%",
          z: 30,
        },
        renderConditions: [
          {
            type: "PROPERTY",
            ref: "enemyHeatUp",
            value: "heatup",
          },
        ],
      },
    ];

    bardsungEnemyTemplate = await DataStore.save(
      new GameCardTemplate({
        title: "Enemy Card",
        slug: templateSlug,
        system: bardsungSystem,
        elements,
        properties,
        size: {
          width: 411.77,
          height: 562.48,
        },
        printSize: {
          width: 63,
          height: 89,
        },
        titleElement: "enemyName",
        exampleCardImage: "/examples/bardsungEnemy.jpeg",
      })
    );
  } else {
    console.debug("found Bardsung Enemy Card template, skipping creation");
  }
};

export const defaultEnemyCardProperties = [
  { propertyRef: "type", value: "standard" },
  { propertyRef: "enemyHeatUp", value: "heatup" },
  { propertyRef: "bannerHeight", value: "single" },
  { propertyRef: "enemyImageStyle", value: "transparent" },
  { propertyRef: "miniBoss", value: "standard" },
];

export const defaultEnemyCardElements = [
  { elementRef: "enemySpeedFront", value: "-" },
  { elementRef: "enemySpeedBack", value: "-" },
  { elementRef: "enemyDefenseFront", value: "-" },
  { elementRef: "enemyDefenseBack", value: "-" },
  { elementRef: "enemyGoldValueFront", value: "-" },
  { elementRef: "enemyGoldValueBack", value: "-" },
  { elementRef: "enemyAttackProfile1ToggleFront", value: "true" },
  { elementRef: "enemyAttackProfile1ToggleBack", value: "true" },
  { elementRef: "enemyAttackProfile1Type", value: "hit" },
  { elementRef: "enemyAttackProfile2Type", value: "miss" },
  { elementRef: "enemyTargetNumberBack", value: "0" },
  { elementRef: "enemyTargetNumberFront", value: "0" },
  {
    elementRef: "enemyAttackProfile1Effects1Front",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  {
    elementRef: "enemyAttackProfile1Effects2Front",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  {
    elementRef: "enemyAttackProfile1Effects3Front",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  { elementRef: "enemyAttackProfile1TypeBack", value: "hit" },
  { elementRef: "enemyAttackProfile2TypeBack", value: "miss" },
  {
    elementRef: "enemyAttackProfile1Effects1Back",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  {
    elementRef: "enemyAttackProfile1Effects2Back",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  {
    elementRef: "enemyAttackProfile1Effects3Back",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  {
    elementRef: "enemyAttackProfile2Effects1Front",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  {
    elementRef: "enemyAttackProfile2Effects2Front",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  {
    elementRef: "enemyAttackProfile2Effects3Front",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  {
    elementRef: "enemyAttackProfile2Effects1Back",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  {
    elementRef: "enemyAttackProfile2Effects2Back",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  {
    elementRef: "enemyAttackProfile2Effects3Back",
    value: `/icons/${templateSlug}/attackEffects/BS-Icons-Grey-Blank.png`,
  },
  { elementRef: "enemyAttackProfile1RangeValueFront", value: "0" },
  { elementRef: "enemyAttackProfile1RangeValueBack", value: "0" },
];
