import { DataProducer } from "./types";
import { doesCardMeetConditions } from "../../../../services/conditions";

export const derivedLookupDataProducer: DataProducer = (element, card) => {
  const derivedValues = element.sourceData.DERIVED_LOOKUP?.values;
  if (!derivedValues) {
    console.error(`Element: ${element} does not contain any values`);
  }

  const matchedCondition = derivedValues?.find(({ conditions }) =>
    doesCardMeetConditions(card, conditions)
  );

  return matchedCondition?.value;
};
