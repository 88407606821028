import { DataStore } from "@aws-amplify/datastore";
import { Predicates } from "aws-amplify";
import { GameSystem, GameCardTemplate, GameCard } from "../../models";
import { initialiseEnemyTemplate } from "./initialiseEnemyTemplate";
import { initialiseHeroTemplate } from "./initialiseHeroTemplate";

export type templateSlugs = "bardsungHero" | "bardsungEnemy";

const templateSlug: templateSlugs = "bardsungHero";

export const getSystemBySlug = async (slug: string) => {
  let [sys] = await DataStore.query(GameSystem, (gs) => gs.slug("eq", slug));
  return sys;
};

export const getTemplateBySlug = async (slug: string) => {
  let [sys] = await DataStore.query(GameCardTemplate, (gs) =>
    gs.slug("eq", slug)
  );
  return sys;
};

const setupGameSystems = async () => {
  console.debug("Initialising game systems...");
  let bardsungSystem: GameSystem = await getSystemBySlug("bardsung");

  if (!bardsungSystem) {
    console.debug("no existing Bardsung system found, creating");

    bardsungSystem = await DataStore.save(
      new GameSystem({
        title: "Bardsung",
        slug: "bardsung",
        icons: [
          {
            label: "Modifier (+1)",
            shortCode: "mod+1",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-1.png`,
          },
          {
            label: "Modifier (+2)",
            shortCode: "mod+2",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-2.png`,
          },
          {
            label: "Modifier (+3)",
            shortCode: "mod+3",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-3.png`,
          },
          {
            label: "Modifier (+4)",
            shortCode: "mod+4",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-4.png`,
          },
          {
            label: "Active",
            shortCode: "active",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Active.png`,
          },
          {
            label: "Area of Effect",
            shortCode: "aoe",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-AOE.png`,
          },
          {
            label: "Armour",
            shortCode: "armour",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-ArmourCard.png`,
          },
          {
            label: "Resilience",
            shortCode: "resilience",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-ArmourValue.png`,
          },
          {
            label: "Aspect",
            shortCode: "aspect",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-AspectCard.png`,
          },
          {
            label: "Attack",
            shortCode: "attack",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-AttackCard.png`,
          },
          {
            label: "Bane",
            shortCode: "bane",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Bane.png`,
          },
          {
            label: "Battle",
            shortCode: "battle",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Battle.png`,
          },
          {
            label: "Bleed",
            shortCode: "bleed",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Bleed.png`,
          },
          {
            label: "Boon",
            shortCode: "boon",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Boon.png`,
          },
          {
            label: "Boss",
            shortCode: "boss",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Boss.png`,
          },
          {
            label: "Bugbear",
            shortCode: "bugbear",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Bugbear.png`,
          },
          {
            label: "Burn",
            shortCode: "burn",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Burn.png`,
          },
          {
            label: "Cautious",
            shortCode: "cautious",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Cautious.png`,
          },
          {
            label: "Chain",
            shortCode: "chain",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Chain.png`,
          },
          {
            label: "Challenge",
            shortCode: "challenge",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Challenge.png`,
          },
          {
            label: "Charm",
            shortCode: "charm",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Charm.png`,
          },
          {
            label: "Consumable",
            shortCode: "consumable",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-ConsumableCard.png`,
          },
          {
            label: "Corpse",
            shortCode: "corpse",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Corpse.png`,
          },
          {
            label: "Corridor",
            shortCode: "corridor",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Corridor.png`,
          },
          {
            label: "Curse",
            shortCode: "curse",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Curse.png`,
          },
          {
            label: "D4",
            shortCode: "d4",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-DamageDiceD4.png`,
          },
          {
            label: "D6",
            shortCode: "d6",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-DamageDiceD6.png`,
          },
          {
            label: "D8",
            shortCode: "d8",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-DamageDiceD8.png`,
          },
          {
            label: "D10",
            shortCode: "d10",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-DamageDiceD10.png`,
          },
          {
            label: "D12",
            shortCode: "d12",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-DamageDiceD12.png`,
          },
          {
            label: "Defeated",
            shortCode: "defeated",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Defeated.png`,
          },
          {
            label: "Delayed",
            shortCode: "delayed",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Delay.png`,
          },
          {
            label: "Devotion",
            shortCode: "devotion",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Devotion.png`,
          },
          {
            label: "Dodge",
            shortCode: "dodge",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Dodge.png`,
          },
          {
            label: "Duergar",
            shortCode: "duergar",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Duergar.png`,
          },
          {
            label: "Dungeon",
            shortCode: "dungeon",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Dungeon.png`,
          },
          {
            label: "Echo",
            shortCode: "echo",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Echo.png`,
          },
          {
            label: "Enemy",
            shortCode: "enemy",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Enemy.png`,
          },
          {
            label: "Magical Advantage",
            shortCode: "ma",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-EnemyMagicalAdvantage.png`,
          },
          {
            label: "Magical Disadvantage",
            shortCode: "md",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-EnemyMagicalDisadvantage.png`,
          },
          {
            label: "Physical Advantage",
            shortCode: "pa",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-EnemyPhysicalAdvantage.png`,
          },
          {
            label: "Physical Disadvantage",
            shortCode: "pd",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-EnemyPhysicalDisadvantage.png`,
          },
          {
            label: "Enemy TN",
            shortCode: "etn",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-EnemyTN.png`,
          },
          {
            label: "Exhaust Consumable",
            shortCode: "exhaust-con",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-ExhaustConsumable.png`,
          },
          {
            label: "Fate Cost",
            shortCode: "fate-cost",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-FateCost.png`,
          },
          {
            label: "Inspire",
            shortCode: "inspire",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-FateGenerate.png`,
          },
          {
            label: "Ferocious",
            shortCode: "ferocious",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Ferocious.png`,
          },
          {
            label: "Firewood",
            shortCode: "firewood",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Firewood.png`,
          },
          {
            label: "Flip Card",
            shortCode: "flip-card",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-FlipCard.png`,
          },
          {
            label: "Flipped Card",
            shortCode: "flipped-card",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-FlippedCard.png`,
          },
          {
            label: "Frostbite",
            shortCode: "frostbite",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Frostbite.png`,
          },
          {
            label: "Gas",
            shortCode: "gas",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Gas.png`,
          },
          {
            label: "Gem Slot 1",
            shortCode: "gem-slot-1",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-GemSlot1.png`,
          },
          {
            label: "Gem Slot 2",
            shortCode: "gem-slot-2",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-GemSlot2.png`,
          },
          {
            label: "Gem Slot 3",
            shortCode: "gem-slot-3",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-GemSlot3.png`,
          },
          {
            label: "Ghost",
            shortCode: "ghost-icon",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Ghost.png`,
          },
          {
            label: "Gold 1",
            shortCode: "gold-1",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Gold1.png`,
          },
          {
            label: "Gold 3",
            shortCode: "gold-3",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Gold3.png`,
          },
          {
            label: "Gold Value",
            shortCode: "gold-value",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-GoldValue.png`,
          },
          {
            label: "Haste",
            shortCode: "haste",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Hasten.png`,
          },
          {
            label: "Healing Potion",
            shortCode: "healing-potion",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-HealingPotion.png`,
          },
          {
            label: "Health",
            shortCode: "health",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Health.png`,
          },
          {
            label: "Hidden Passage",
            shortCode: "hidden-passage",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-HiddenTunnel.png`,
          },
          {
            label: "Fatigue",
            shortCode: "fatigue",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-HinderFatigue.png`,
          },
          {
            label: "Hobgoblin",
            shortCode: "hobgoblin",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Hobgoblin.png`,
          },
          {
            label: "Immediate",
            shortCode: "immediate",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Immediate.png`,
          },
          {
            label: "Infected",
            shortCode: "infected",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Infected.png`,
          },
          {
            label: "Level 1",
            shortCode: "level-1",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Level1.png`,
          },
          {
            label: "Level 2",
            shortCode: "level-2",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Level2.png`,
          },
          {
            label: "level 3",
            shortCode: "level-3",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Level3.png`,
          },
          {
            label: "Lever",
            shortCode: "lever",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Lever.png`,
          },
          {
            label: "Light Wound",
            shortCode: "light-wound",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-LightWound.png`,
          },
          {
            label: "Magical",
            shortCode: "magical",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Magical.png`,
          },
          {
            label: "Mechanica",
            shortCode: "mechanica",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Mechanica.png`,
          },
          {
            label: "Mirage",
            shortCode: "mirage",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Mirage.png`,
          },
          {
            label: "Narrative",
            shortCode: "narrative",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Narrative.png`,
          },
          {
            label: "One Use",
            shortCode: "one-use",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-OneUse.png`,
          },
          {
            label: "Party",
            shortCode: "party",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Party.png`,
          },
          {
            label: "Passive",
            shortCode: "passive",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Passive.png`,
          },
          {
            label: "Path of Beasts",
            shortCode: "path-of-beasts",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfBeasts.png`,
          },
          {
            label: "Path of Blood",
            shortCode: "path-of-blood",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfBlood.png`,
          },
          {
            label: "Path of Damnation",
            shortCode: "path-of-damnation",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfDamnation.png`,
          },
          {
            label: "Path of Elements",
            shortCode: "path-of-elements",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfElements.png`,
          },
          {
            label: "Path of Faith",
            shortCode: "path-of-faith",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfFaith.png`,
          },
          {
            label: "Path of Flame",
            shortCode: "path-of-flame",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfFlame.png`,
          },
          {
            label: "Path of Honour",
            shortCode: "path-of-honour",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfHonour.png`,
          },
          {
            label: "Path of Omens",
            shortCode: "path-of-omens",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfOmens.png`,
          },
          {
            label: "Path of Serenity",
            shortCode: "path-of-serenity",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfSerenity.png`,
          },
          {
            label: "Path of Shadow",
            shortCode: "path-of-shadow",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfShadow.png`,
          },
          {
            label: "Path of Spirits",
            shortCode: "path-of-spirits",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfSpirits.png`,
          },
          {
            label: "Path of Steel",
            shortCode: "path-of-steel",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfSteel.png`,
          },
          {
            label: "Path of Wilds",
            shortCode: "path-of-wilds",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-PathOfWilds.png`,
          },
          {
            label: "Physical",
            shortCode: "physical",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Physical.png`,
          },
          {
            label: "Pinned",
            shortCode: "pinned",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Pinned.png`,
          },
          {
            label: "Poison",
            shortCode: "poison",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Poison.png`,
          },
          {
            label: "Possessed",
            shortCode: "possessed",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Possessed.png`,
          },
          {
            label: "Powerful",
            shortCode: "powerful",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Powerful.png`,
          },
          {
            label: "Pulse",
            shortCode: "pulse",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Pulse.png`,
          },
          {
            label: "Push",
            shortCode: "push",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Push.png`,
          },
          {
            label: "Quicken",
            shortCode: "quicken",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Quicken.png`,
          },
          {
            label: "Range",
            shortCode: "range",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Range.png`,
          },
          {
            label: "Reposition",
            shortCode: "reposition",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Reposition.png`,
          },
          {
            label: "Room",
            shortCode: "room",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Room.png`,
          },
          {
            label: "Rune",
            shortCode: "rune",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Rune.png`,
          },
          {
            label: "Serious Wound",
            shortCode: "serious-wound",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-SeriousWound.png`,
          },
          {
            label: "Shock",
            shortCode: "shock",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Shock.png`,
          },
          {
            label: "Silence",
            shortCode: "silence",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Silence.png`,
          },
          {
            label: "Skill",
            shortCode: "skill",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-SkillCard.png`,
          },
          {
            label: "Slow",
            shortCode: "slow",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Slow.png`,
          },
          {
            label: "Speed",
            shortCode: "speed",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Speed.png`,
          },
          {
            label: "Spirit",
            shortCode: "spirit",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Spirit.png`,
          },
          {
            label: "Spore",
            shortCode: "spore",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Spore.png`,
          },
          {
            label: "Squire",
            shortCode: "squire",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Squire1Player.png`,
          },
          {
            label: "Stop",
            shortCode: "stop",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Stop.png`,
          },
          {
            label: "Stunned",
            shortCode: "stunned",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Stunned.png`,
          },
          {
            label: "Sundered",
            shortCode: "sundered",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Sundered.png`,
          },
          {
            label: "Sustained",
            shortCode: "sustained",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Sustained.png`,
          },
          {
            label: "Timer",
            shortCode: "timer",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Timer.png`,
          },
          {
            label: "Toolkit",
            shortCode: "toolkit",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Toolkit.png`,
          },
          {
            label: "Topper Card",
            shortCode: "topper",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-TopperCard.png`,
          },
          {
            label: "Toughness",
            shortCode: "toughness",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-ToughnessValue.png`,
          },
          {
            label: "Gemstone",
            shortCode: "gemstone",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Treasure.png`,
          },
          {
            label: "Undead",
            shortCode: "undead",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Undead.png`,
          },
          {
            label: "Unexhaust Gold Cost",
            shortCode: "ugc",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-UnexhaustGoldCost.png`,
          },
          {
            label: "Engagement",
            shortCode: "engagement",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Victory.png`,
          },
          {
            label: "Wandering Monster",
            shortCode: "wandering-monster",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-WanderingMonster.png`,
          },
          {
            label: "Water",
            shortCode: "water",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Water.png`,
          },
          {
            label: "Weaken",
            shortCode: "weaken",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-Weaken.png`,
          },
          {
            label: "Weapon",
            shortCode: "weapon",
            iconPath: `/icons/${templateSlug}/BS-Icons-Gold-WeaponCard.png`,
          },
          {
            label: "CHA",
            shortCode: "cha",
            iconPath: `/icons/${templateSlug}/BS-Icons-Hero-Generic-CHA.png`,
          },
          {
            label: "CON",
            shortCode: "con",
            iconPath: `/icons/${templateSlug}/BS-Icons-Hero-Generic-CON.png`,
          },
          {
            label: "DEX",
            shortCode: "dex",
            iconPath: `/icons/${templateSlug}/BS-Icons-Hero-Generic-DEX.png`,
          },
          {
            label: "INT",
            shortCode: "int",
            iconPath: `/icons/${templateSlug}/BS-Icons-Hero-Generic-INT.png`,
          },
          {
            label: "STR",
            shortCode: "str",
            iconPath: `/icons/${templateSlug}/BS-Icons-Hero-Generic-STR.png`,
          },
          {
            label: "WIS",
            shortCode: "wis",
            iconPath: `/icons/${templateSlug}/BS-Icons-Hero-Generic-WIS.png`,
          },
        ],
      })
    );
  } else {
    console.debug("found Bardsung system, skipping creation");
  }
};

const setupTemplates = async () => {
  console.debug("Initialising templates...");

  initialiseHeroTemplate();
  initialiseEnemyTemplate();
};

export const initialiseData = async () => {
  await clearDatastore();

  console.debug("Initialising data...");
  await setupGameSystems();
  await setupTemplates();
  console.debug("Data initialisation completed!");
};

export const clearDatastore = async () => {
  console.debug("clearing started");
  await DataStore.delete(GameCardTemplate, Predicates.ALL);
  await DataStore.delete(GameCard, Predicates.ALL);
  await DataStore.delete(GameSystem, Predicates.ALL);
  console.debug("clearing finished");
};
